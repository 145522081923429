import { makeStyles } from '@material-ui/core/styles';

import { LOGO_WIDTH } from 'theme';


export const styles = makeStyles(() => ({
  container: {
    width: LOGO_WIDTH,
    '& > a': {
      display: 'block',
      lineHeight: 0,
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '70px',
    },
  },
}));
