const config = {
  firebase: {
    apiKey: 'AIzaSyAzObLW_brnUbql9nRoBYiqk1BhCH1U4MQ',
    authDomain: 'manualpro-44dbd.firebaseapp.com',
    databaseURL: 'https://manualpro-44dbd.firebaseio.com',
    projectId: 'manualpro-44dbd',
    storageBucket: 'manualpro-44dbd.appspot.com',
    messagingSenderId: '996182456737',
    appId: '1:996182456737:web:ef1080f99b86880a14b3e0',
    measurementId: 'G-72FMEX0G03',
  },
  stripePublicAPIKey: 'pk_live_EIo6xiFoLLqwxv7vbGRQY2l9006N7LfUgA',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCpjfzUDauKdyI1n5-JgbiYSIfZgzA2rWM&libraries=places,geometry',
  },
};


export default config;
