import { currencyValues } from '@pro/web-common/utils/currency';


export const FOOTER_TEXT_LINE_1 = '© 2021 Kappow Apps';
export const FOOTER_TEXT_LINE_2 = 'All rights reserved.';
export const MANAGER_FORM_CHOOSE_ITEM_LABEL = 'Choose products';
export const MANUAL_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Manual for Product';
export const MENU_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Menu for Product';
export const TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Timetable for Product';
export const ALL_ITEMS_LABEL = 'All Products';
export const PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT = 'Please finish Product configuration';
export const PRODUCT_CONFIGURATION_ITEM_NAME_LABEL = 'Product Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER = 'Add Product Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR = 'Add Product name;';
export const PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR = 'Add minimum four pages for your Product;';
export const PRODUCT_FILTERS_SELECT_ITEM_LABEL = 'Select product';
export const PRODUCTS_LISTING_NO_DATA_TEXT = 'You have not added products yet';
export const PRODUCT_ITEM_COPY_TEXT = 'Duplicate product';
export const PRODUCT_ITEM_SUBSCRIBE_TEXT = 'Please subscribe to add more products';
export const PRODUCT_ITEM_RESUME_ACCESS_TEXT = 'Resume product access';
export const PRODUCT_PAGE_ADD_VERSIONS_TEXT = 'Add versions';
export const PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT = 'Please subscribe to add manual language versions';
export const QUICK_MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added quick messages for the selected product yet';
export const MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added messages for the selected product yet';
export const RTF_PAGE_EDITOR_TITLE = 'Add text page for Product';
export const ADMIN_PRODUCTS_TITLE = 'Products';
export const ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL = 'Add Product';
export const getAdminProductsAddMoreItemsText = (availableProducts) => `You can add ${availableProducts} more product${availableProducts > 1 ? 's' : ''}`;
export const ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT = 'Add subscription to get more features or add more products';
export const ADD_FIRST_ITEM_TITLE = 'Add your first Product';
export const ADD_PRODUCT_TITLE = 'Add Product';
export const ADD_PRODUCT_SUBTITLE = 'Add a product + any support pages';
export const ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT = 'add new products';
export const ADMIN_HOME_TITLE = 'My Home';
export const ADMIN_HOME_SUBTITLE = 'All your products are listed here with the message tags Users can use to filter messages you send them. Edit your product pages or add message tags (subscription only).';
export const ADMIN_SUBSCRIPTIONS_TITLE = 'My Subscriptions';
export const ADMIN_SUBSCRIPTIONS_SUBTITLE = 'Manage your subscriptions. Subscribe more products in singles or buy a pack of ten!';
export const ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL = 'Contact us for more than 10 products';
export const ADMIN_USERS_TITLE = 'My Users';
export const ADMIN_USERS_SUBTITLE = 'Manage product users';
export const EDIT_PRODUCT_SUBTITLE = 'Product + any support pages';
export const EDIT_PRODUCT_LIMITED_ACCESS_NOTE = 'NOTE: Product is limited in access. You will not be able to save your updates!';
export const QUICK_MESSAGES_TITLE = 'Quick Messaging';
export const QUICK_MESSAGES_SUBTITLE = 'Manage quick messaging for your products';
export const QUICK_MESSAGES_NO_ITEMS_NOTE = 'NOTE: You have not added any products or have limited access, contact support chat if this is unexpected.';
export const LOCAL_DIRECTORY_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Directories for Product';
export const PROFILE_HOME_TITLE = 'My Home';
export const PROFILE_HOME_SUBTITLE = 'All your products are listed here.';

export const DEFAULT_MANUAL_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default manual section title',
      html: '<p>Default manual section content</p>',
      image: null,
      subsections: [
        {
          id: 2,
          title: 'Default manual sub section title',
          html: '<p>Default manual sub section content</p>',
          image: null,
        },
      ],
    },
  ],
};
export const DEFAULT_MENU_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default menu title',
      html: '<p>Default menu content</p>',
      image: null,
      isVisible: true,
      subsections: [
        {
          id: 2,
          title: 'Default menu item title',
          html: '<p>Default menu item content</p>',
          image: null,
          currencyId: currencyValues[0].value,
          isVisible: true,
        },
      ],
    },
  ],
};

export const DEFAULT_TIMETABLE_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default Timetable Item - 9.00am',
      html: '<p>Default Timetable Item - 9.00am</p>',
      image: null,
      isVisible: true,
    },
  ],
};

export const DEFAULT_ISSUE_PAGE_CONTENT = {
  page: {
    title: 'Default page title',
    isVisible: true,
    html: "<p><span style=\"font-family:'Archivo Narrow';\">Default page content</span></p>",
    typeface: 'Source Sans Pro',
    subsections: [
      {
        titleBackgroundColor: {
          b: 41,
          g: 115,
          r: 45,
          a: 1,
        },
        id: 2,
        title: 'Default section title',
        typeface: 'IBM Plex Mono',
        image: null,
        isVisible: true,
        html: '<p><span style="font-family:Arvo;">Default section content</span></p><p>&nbsp;</p><figure class="image"><img src="https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4"></figure>',
      },
    ],
    image: {
      file: null,
      filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
    },
    id: '14ec2616-6d4a-416c-90e3-b025362f9cb3',
    titleBackgroundColor: {
      g: 224,
      b: 173,
      r: 188,
      a: 1,
    },
  },
};


export const DEFAULT_MAGAZINE_ISSUES = [
  {
    startDate: {
      seconds: 1638350760,
      nanoseconds: 0,
    },
    coverTitlesMaxWidth: 100,
    coverTitleHtml: "<figure class=\"image image-style-align-right\"><img src=\"https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2F6a1cdaff-81d1-436a-9459-b4b46db19b1b.png?alt=media&token=3fad4976-a228-463e-8065-75f8f66c0ec3\"></figure><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><h1 style=\"text-align:right;\"><span style=\"background-color:hsl(0,0%,0%);color:hsl(0,0%,100%);font-family:'Changa One';\">These are my titles.</span></h1><h2 style=\"text-align:right;\"><span style=\"background-color:hsl(0,0%,0%);color:hsl(0,0%,100%);font-family:'Changa One';\">Another title.</span></h2>",
    coverImage: {
      url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
      ref: 'Cover Image',
    },
    coverTitlesOpacity: 61,
    showIssueName: true,
    name: '<p class="ck-heading_paragraph--small" style="text-align:right;">Issue date/name</p>',
    endDate: null,
    pages: [
      {
        isExist: true,
        title: 'First page added',
        isVisible: true,
        html: "<p><span style=\"font-family:'Archivo Narrow';\">Default page content</span></p>",
        typeface: 'Source Sans Pro',
        subsections: [
          {
            titleBackgroundColor: {
              b: 41,
              g: 115,
              r: 45,
              a: 1,
            },
            id: 2,
            title: 'Default section title',
            typeface: 'IBM Plex Mono',
            image: null,
            isVisible: true,
            html: '<p><span style="font-family:Arvo;">Default section content</span></p><p>&nbsp;</p><figure class="image"><img src="https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4"></figure>',
          },
        ],
        image: {
          file: null,
          filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
        },
        id: '14ec2616-6d4a-416c-90e3-b025362f9cb3',
        titleBackgroundColor: {
          g: 224,
          b: 173,
          r: 188,
          a: 1,
        },
      },
      {
        image: {
          file: null,
          filePath: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_983f3bf8-26c0-40c0-bfed-a0d32fdcc086.png?alt=media&token=4bc12f54-6c14-4509-8516-4377ed4ef6c4',
        },
        id: '91fd90be-b603-4715-ab62-0ab597fa7ce5',
        title: 'Second page added',
        subsections: [
          {
            titleBackgroundColor: {
              g: 193,
              b: 193,
              a: 1,
              r: 215,
            },
            typeface: 'IBM Plex Serif',
            id: 2,
            title: 'This is section heading',
            image: null,
            html: "<h1>Here's a heading.</h1><p>Then some text.</p>",
            isVisible: true,
          },
        ],
        isExist: true,
        isVisible: true,
        typeface: 'Gelasio',
        html: "<p><span style=\"font-family:'Changa One';\">Default page content</span></p><p><span style=\"font-family:'Changa One';\">More content.</span></p><figure class=\"image\"><img src=\"https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fad0a986a-4b9b-4bb4-8023-2a376ab4ac03.png?alt=media&token=505ca9fb-1ef6-46ed-9f27-3a756c39fe16\"></figure>",
        titleBackgroundColor: {
          g: 71,
          r: 210,
          b: 187,
          a: 1,
        },
      },
    ],
  },
];

export const DEFAULT_MAGAZINE_CONFIG = {
  imprintLogo: {
    ref: 'Imprint Logo',
    url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_b8220fa9-4ad3-4f0a-b5ba-dd58c6c6c4b2.png?alt=media&token=9d01344e-ae05-457a-95fe-3e9231c13753',
  },
  typeface: 'Cabin',
  imprintHtml: '<p class="ck-heading_paragraph--small">This is where the IMPRINT goes. Small copy and maybe a logo.<br>2021 © Kappow Apps Limited</p>',
  imprintBackgroundColor: {
    g: 237,
    a: 1,
    b: 11,
    r: 237,
  },
  logo: {
    ref: 'Logo',
    url: 'https://firebasestorage.googleapis.com/v0/b/magazzn-73900.appspot.com/o/mocks%2Fproducts_0137dd99-d381-4fa3-bc86-91a9dba347bc_b8220fa9-4ad3-4f0a-b5ba-dd58c6c6c4b2.png?alt=media&token=9d01344e-ae05-457a-95fe-3e9231c13753',
  },
};

