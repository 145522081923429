import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import ContactItem from '@pro/web-common/components/contact-item';


const ContactsListing = React.memo(({ contacts, onEdit, onDelete }) => (
  <>
    {contacts.map((data, index) => (
      <ContactItem
        key={index} // eslint-disable-line react/no-array-index-key
        onEdit={() => onEdit({ index, ...data })}
        onDelete={() => onDelete(index)}
        {...data}
      />
    ))}

    {contacts.length === 0 && (
      <Box
        textAlign="center"
        p={2}
      >
        <Typography variant="body2">You have not added contacts yet</Typography>
      </Box>
    )}
  </>
));

ContactsListing.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    contactName: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
  })),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ContactsListing.defaultProps = {
  contacts: [],
};


export default ContactsListing;
