import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
} from '@material-ui/core';

import { withFormik } from 'formik';

import infoJson from 'constants/info';
import InfoSection from '@pro/web-common/components/info-section';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import Select from '@pro/web-common/components/select';
import DatePicker from '@pro/web-common/components/date-picker';
import TimePicker from '@pro/web-common/components/time-picker';
import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';
import PageLinkCopy from '@pro/web-common/components/page-link-copy';

import { isTestVersion } from 'constants/product-config';

import { formValidationSchema } from './config';
import { getMenuOptions, getPagesOptions } from './utils';
import { styles } from './styles';


const TrackTraceMessageForm = React.memo(({ fetching, isError, onSubmit, onClose, productsOptions, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = formikProps;

  const menuOptions = useMemo(() => getMenuOptions(productsOptions, values.productId), [values.productId]);
  const copyPageOptions = useMemo(() => getPagesOptions(productsOptions, values.productId), [values.productId]);

  useEffect(() => {
    if (!isTestVersion) {
      setFieldValue('productPageId', menuOptions[0] ? menuOptions[0].value : '');
    }
  }, [menuOptions]);

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          {copyPageOptions.length && (
            <Grid
              item
              xs={12}
            >
              <PageLinkCopy
                pages={copyPageOptions}
                infoText="You can insert link to the other pages. It will navigate mobile app users to other app screens"
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
          >
            {productsOptions && (
              <InfoSection infoMessage={infoJson.message.audience}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    id="productId"
                    name="productId"
                    label="Select audience"
                    options={productsOptions}
                    value={values.productId}
                    onChange={({ target: { value } }) => setFieldValue('productId', value)}
                  />
                </FormControl>
              </InfoSection>
            )}
          </Grid>

          <Grid
            item
            xs={12}
          >
            {!isTestVersion && menuOptions && menuOptions.length > 0 && (
              <InfoSection infoMessage={infoJson.message.audience}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    id="menu"
                    name="menu"
                    label="Select Menu/Timetable"
                    options={menuOptions}
                    value={values.productPageId}
                    onChange={({ target: { value } }) => setFieldValue('productPageId', value)}
                  />
                </FormControl>
              </InfoSection>
            )}
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="message"
              label="Message"
              placeholder="Message"
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.message && touched.message}
              autoFocus
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="link"
              label="Link to form or page"
              placeholder="Link to form or page"
              name="link"
              value={values.link}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.link && touched.link}
            />
          </Grid>

          <Grid
            item
            xs={7}
          >
            <DatePicker
              clearable
              fullWidth
              variant="dialog"
              id="checkInDate"
              label="Check In Date"
              value={values.checkInDate}
              error={!!errors.checkInDate && touched.checkInDate}
              onChange={(value) => setFieldValue('checkInDate', value)}
            />
          </Grid>

          <Grid
            item
            xs={5}
          >
            <TimePicker
              clearable
              fullWidth
              variant="dialog"
              id="checkInTime"
              label="Check In Time"
              value={values.checkInTime}
              error={!!errors.checkInTime && touched.checkInTime}
              onChange={(value) => setFieldValue('checkInTime', value)}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Box mr={1}>
              <Button
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

TrackTraceMessageForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    link: PropTypes.string,
    productsIds: PropTypes.arrayOf(PropTypes.string),
  }),
  productsOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    menuPages: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
};

TrackTraceMessageForm.defaultProps = {
  initialValues: {},
  isError: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, defaultProductId, productsOptions }) => {
    const menuOptions = getMenuOptions(productsOptions, defaultProductId);

    const {
      message = '',
      link = '',
      checkInDate = null,
      checkInTime = null,
      productPageId = menuOptions[0] ? menuOptions[0].value : '',
    } = initialValues || {};

    const values = {
      type: MESSAGE_TYPE.TRACK_TRACE,
      message,
      link,
      checkInDate,
      checkInTime,
      productId: defaultProductId,
      ...(isTestVersion ? {} : { productPageId }),
    };

    return values;
  },
  handleSubmit: ({ productId, ...values }, { props: { onSubmit } }) => {
    const productsIds = [productId];

    onSubmit({
      data: {
        ...values,
        productsIds,
      },
    });
  },
  validationSchema: formValidationSchema,
  enableReinitialize: true,
})(TrackTraceMessageForm);
