import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  hiddenInput: {
    display: 'none',
  },
  fileInputText: ({ error }) => ({
    width: '100%',
    height: '37px',
    padding: '7px 14px',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: error ? theme.palette.error.main : theme.palette.divider,
    fontSize: '0.8125rem',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  }),
  fileInputButton: {
    paddingTop: '7px',
    paddingBottom: '7px',
    flex: 1,
  },
  fileInputButtonLabel: {
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));
