import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#50AFE6',
      main: '#234D65',
      additional: '#3E88B3',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Arvo',
  },
});

export const LOGO_WIDTH = '200px';

export default theme;
