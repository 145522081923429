import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
} from '@material-ui/core';

import { getUserInfo } from '@pro/web-common/core/user/selectors';
import { ERROR_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { actions as profileActions } from '@pro/web-common/core/profile/actions';

import PageTitle from '@pro/web-common/components/page-title';
import DirectoryProfileForm from '@pro/web-common/components/directory-profile-form';

import {
  PROFILE_HOME_TITLE,
  PROFILE_HOME_SUBTITLE,
} from 'content/texts';


const ProfileHome = React.memo(({ profile, updateProfile, openModal }) => {
  const showErrorModal = useCallback(({ errors, errorsTitle }) => {
    openModal({
      modal: ERROR_ALERT,
      params: { errors, errorsTitle },
    });
  }, [openModal]);

  const handleSubmit = useCallback((data) => updateProfile({ profile: data }), []);

  return (
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <PageTitle
            title={PROFILE_HOME_TITLE}
            subtitle={PROFILE_HOME_SUBTITLE}
          />

          <Box mt={4}>
            <Card>
              <CardContent>
                <DirectoryProfileForm
                  isEdit
                  initialValues={profile}
                  isAuthorized
                  isOwner
                  onSubmit={handleSubmit}
                  onError={showErrorModal}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
});

ProfileHome.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  openModal: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

ProfileHome.defaultProps = {};

const mapStateToProps = (state) => ({
  profile: getUserInfo(state),
});

const mapDispatchToProps = {
  openModal: modalConductorActions.openModal,
  updateProfile: profileActions.updateProfileByOwner,
};


export default connect(mapStateToProps, mapDispatchToProps)(ProfileHome);
