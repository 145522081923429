import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import { Container, Box } from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';
import { actions as brandActions } from '@pro/web-common/core/brand/actions';
import { getBrandUpdatingState } from '@pro/web-common/core/brand/selectors';

import DataWithLoader from '@pro/web-common/components/data-with-loader';
import BrandConfigurator from '@pro/web-common/components/brand-configurator';
import PageTitle from '@pro/web-common/components/page-title';


const ConfigureBrand = React.memo(({ updatingState: { error, fetching }, updateBrand }) => {
  const { brand } = useContext(BrandContext);
  const { organisationName, isConfigured } = brand || {};

  return (
    <Container maxWidth="md">
      <DataWithLoader isLoaded={!isNil(brand)}>
        <Box p={2}>
          <PageTitle
            title={`Your Brand - ${organisationName}`}
            subtitle="Create your app branding. Add your logo, choose your colours and select a typeface to match your brand."
          />

          <BrandConfigurator
            withLogoEnabled
            initialValues={brand}
            fetching={fetching}
            isError={!!error}
            onSubmit={updateBrand}
            submitLabel={isConfigured ? 'Save' : 'Next'}
          />
        </Box>
      </DataWithLoader>
    </Container>
  );
});

ConfigureBrand.propTypes = {
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  updateBrand: PropTypes.func.isRequired,
};

ConfigureBrand.defaultProps = {

};


const mapStateToProps = (state) => ({
  updatingState: getBrandUpdatingState(state),
});

const mapDispatchToProps = {
  updateBrand: brandActions.updateBrand,
};


export default connect(mapStateToProps, mapDispatchToProps)(ConfigureBrand);
