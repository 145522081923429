import * as Yup from 'yup';

import { ALL_ITEMS_LABEL } from 'content/texts';


export const formValidationSchema = Yup.object().shape({
  title: Yup.string().required(),
});

export const ALL_PRODUCTS_OPTION = {
  value: 'all',
  label: ALL_ITEMS_LABEL,
};
