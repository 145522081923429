import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { colorPropsSchema } from '@pro/web-common/utils/style';

import { styles } from './styles';


const IssuesPreview = React.memo(({
  coverImage,
  coverTitleHtml,
  coverTitlesMaxWidth,
  coverTitlesOpacity,
  logo,
  name,
  showIssueName,
  typeface,
  imprintBackgroundColor,
}) => {
  const classes = styles({ coverImage, typeface, coverTitlesMaxWidth, coverTitlesOpacity, imprintBackgroundColor });

  return (
    <Box className={classes.container}>
      <Box>
        {
          logo && (
            <Box className={classes.logoContainer}>
              <img
                src={logo.url}
                alt=""
                className={classes.logo}
              />
            </Box>

          )
        }

        {
          showIssueName && (
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: name }} // eslint-disable-line react/no-danger
            />
          )
        }
      </Box>

      {
        coverTitleHtml && (
          <Box className={classes.htmlContainer}>
            <Box
              maxWidth={`${coverTitlesMaxWidth}%`}
              width="100%"
            >
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: coverTitleHtml }} // eslint-disable-line react/no-danger
              />
            </Box>
          </Box>

        )
      }
    </Box>
  );
});


IssuesPreview.propTypes = {
  coverImage: PropTypes.shape({
    ref: PropTypes.string,
    url: PropTypes.string,
  }),
  coverTitleHtml: PropTypes.string,
  coverTitlesMaxWidth: PropTypes.number,
  coverTitlesOpacity: PropTypes.number,
  logo: PropTypes.shape({
    ref: PropTypes.string,
    url: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  showIssueName: PropTypes.bool.isRequired,
  typeface: PropTypes.string.isRequired,
  imprintBackgroundColor: colorPropsSchema.isRequired,
};

IssuesPreview.defaultProps = {
  coverImage: null,
  coverTitleHtml: null,
  coverTitlesMaxWidth: 100,
  coverTitlesOpacity: 100,
  logo: null,
};


export default IssuesPreview;

