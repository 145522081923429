import { eventChannel } from 'redux-saga';
import { put, call, takeLatest, select, take, all, cancelled } from 'redux-saga/effects';

import history from '@pro/web-common/core/history';
import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getBrandId, getRole, getUserUID } from '@pro/web-common/core/user/selectors';
import FileService from '@pro/web-common/core/file/service';

import { getIsOwner, getIsDemoUser, getIsProfile } from '@pro/web-common/core/user/utils';
import { generateId } from '@pro/web-common/utils';
import LocationService from '@pro/web-common/services/location';

import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';

import { MODAL_MESSAGE } from 'content/modals';

import { getPagesFiles, normalizePagePaths, normalizeProducts, normalizeProduct, getLocalDirectoryProfileIds } from './utils';
import ProductService from './service';
import { constants } from './actions';


/*
 * Sagas
 */
function* init () {
  yield all([
    call(subscribeSagaToUserSession, watchProducts, true, true),
    call(subscribeSagaToUserSession, watchProductsOrder),
  ]);
}

function* getWatchProductsChannel () {
  const userId = yield select(getUserUID);
  const brandId = yield select(getBrandId);
  const userRole = yield select(getRole);
  const isOwner = getIsOwner(userRole);
  const isProfile = getIsProfile(userRole);
  let requestParams;

  if (isOwner) {
    requestParams = ['brandId', '==', brandId];
  } else if (isProfile) {
    requestParams = ['profileIds', 'array-contains', userId];
  } else {
    requestParams = ['users', 'array-contains', userId];
  }

  return eventChannel((emit) => {
    const unsubscribe = ProductService.watchProducts((data) => emit({ data }), requestParams);
    return unsubscribe;
  });
}

function* watchProducts () {
  const channel = yield call(getWatchProductsChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);
      const normalizedData = normalizeProducts(data);

      yield put(resolvedAction(constants.WATCH_PRODUCTS, { data: normalizedData }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_PRODUCTS, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getWatchProductsOrderChannel () {
  const brandId = yield select(getBrandId);

  return eventChannel((emit) => {
    const unsubscribe = ProductService.watchProductsOrder((data) => emit({ data }), brandId);
    return unsubscribe;
  });
}

function* watchProductsOrder () {
  const channel = yield call(getWatchProductsOrderChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);

      if (!data) {
        throw new Error('Products order is not set yet.');
      }

      const { orders } = data;

      yield put(resolvedAction(constants.WATCH_PRODUCTS_ORDER, { data: orders }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_PRODUCTS_ORDER, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* normalizeProductPages (productId, pages, withDemoPrefix) {
  const files = getPagesFiles(pages);
  const filesStoragePrefix = `${withDemoPrefix ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`;
  const filesPaths = yield call(FileService.uploadFiles, files, filesStoragePrefix);
  const normalizedPages = yield call(normalizePagePaths, pages, filesPaths);

  return normalizedPages;
}

function* saveProductsOrder ({ payload: { order } }) {
  try {
    const brandId = yield select(getBrandId);

    yield call(ProductService.saveProductsOrder, brandId, {
      brandId,
      orders: order,
    });

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.SAVE_PRODUCTS_ORDER_SUCCESS },
    }));

    yield put(resolvedAction(constants.SAVE_PRODUCTS_ORDER, { data: order }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SAVE_PRODUCTS_ORDER, { error: message }));
  }
}

function* createProduct ({ payload: { productName, address, pushMessagesRadius, pages, tags, productId } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);

    const brandId = yield select(getBrandId);
    const normalizedPages = yield call(normalizeProductPages, productId, pages, isDemoUser);
    const { lat, lng } = yield call(LocationService.getLocationLatLng, address);
    const profileIds = getLocalDirectoryProfileIds(normalizedPages);

    const product = {
      brandId,
      productName,
      address,
      lat,
      lng,
      pushMessagesRadius,
      pages: normalizedPages,
      tags,
      users: [],
      isVisible: true,
      ...(profileIds ? { profileIds } : {}),
    };

    if (!isDemoUser) {
      yield call(ProductService.createProduct, productId, product);
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PRODUCT_CREATE_SUCCESS },
    }));

    const productData = {
      id: generateId(),
      ...product,
    };

    const resolvedData = isDemoUser ? { data: normalizeProduct(productData) } : null;
    yield put(resolvedAction(constants.CREATE_PRODUCT, resolvedData));

    history.push('/admin-home');
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_PRODUCT, { error: message }));
  }
}

function* updateProduct ({ payload: { id, productName, address, pushMessagesRadius, tags, pages, isVisible, showSubmitSuccess } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);

    const normalizedPages = yield call(normalizeProductPages, id, pages);
    const { lat, lng } = yield call(LocationService.getLocationLatLng, address);
    const profileIds = getLocalDirectoryProfileIds(normalizedPages);

    if (!isDemoUser) {
      yield call(ProductService.updateProduct, id, {
        productName,
        address,
        lat,
        lng,
        pushMessagesRadius,
        pages: normalizedPages,
        tags,
        isVisible,
        updatedAt: new Date(),
        ...(profileIds ? { profileIds } : {}),
      });
    }

    if (showSubmitSuccess) {
      yield put(modalConductorActions.openModal({
        modal: SUCCESS_ALERT,
        params: { message: MODAL_MESSAGE.PRODUCT_UPDATE_SUCCESS },
      }));
    }

    const productData = {
      id,
      productName,
      address,
      lat,
      lng,
      pushMessagesRadius,
      pages: normalizedPages,
      tags,
      isVisible,
      ...(profileIds ? { profileIds } : {}),
    };
    const resolvedData = isDemoUser ? { data: normalizeProduct(productData) } : null;

    yield put(resolvedAction(constants.UPDATE_PRODUCT, resolvedData));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_PRODUCT, { error: message }));
  }
}

function* deleteProduct ({ payload: { id } }) {
  const role = yield select(getRole);
  const isDemoUser = getIsDemoUser(role);

  try {
    if (!isDemoUser) {
      yield call(ProductService.deleteProduct, id);
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PRODUCT_DELETE_SUCCESS },
    }));

    yield put(resolvedAction(constants.DELETE_PRODUCT, { id }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_PRODUCT, {
      error: message,
      id,
    }));
  }
}

function* unlockProduct ({ payload: { id } }) {
  try {
    yield call(ProductService.unlockProduct, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PRODUCT_UNLOCK_SUCCESS },
    }));

    yield put(resolvedAction(constants.UNLOCK_PRODUCT));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UNLOCK_PRODUCT, {
      error: message,
      id,
    }));
  }
}

function* copyProduct ({ payload: { id } }) {
  try {
    yield call(ProductService.copyProduct, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PRODUCT_COPY_SUCCESS },
    }));

    yield put(resolvedAction(constants.COPY_PRODUCT));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.COPY_PRODUCT, { error: message }));
  }
}

/*
 * Watchers
 */
function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* createProductWatcher () {
  yield takeLatest(constants.CREATE_PRODUCT, createProduct);
}

function* updateProductWatcher () {
  yield takeLatest(constants.UPDATE_PRODUCT, updateProduct);
}

function* deleteProductWatcher () {
  yield takeLatest(constants.DELETE_PRODUCT, deleteProduct);
}

function* unlockProductWatcher () {
  yield takeLatest(constants.UNLOCK_PRODUCT, unlockProduct);
}

function* copyProductWatcher () {
  yield takeLatest(constants.COPY_PRODUCT, copyProduct);
}

function* saveProductsOrderWatcher () {
  yield takeLatest(constants.SAVE_PRODUCTS_ORDER, saveProductsOrder);
}


export default [
  initWatcher,
  createProductWatcher,
  updateProductWatcher,
  deleteProductWatcher,
  unlockProductWatcher,
  copyProductWatcher,
  saveProductsOrderWatcher,
];
