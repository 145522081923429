import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';



export const styles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },
  contentContainer: ({ typeface, imprintBackgroundColor, imprintFontColor }) => ({
    padding: theme.spacing(1),
    fontFamily: typeface,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: parseRgbaColor(imprintFontColor),
    backgroundColor: parseRgbaColor(imprintBackgroundColor),
  }),
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(-1, -2, 0, -2),
  },
  logo: {
    display: 'block',
  },
  issueName: ({ typeface }) => ({
    textTransform: 'uppercase',
    textAlign: 'right',
    margin: theme.spacing(0.3, 0, 0, 0),
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: typeface,
  }),
  pageNavigationContainer: {
    margin: theme.spacing(3, 0),
  },
  pageNavigationTitle: ({ typeface }) => ({
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: typeface,
    marginBottom: '8px',
  }),
  pageNameContainer: ({ sectionBackgroundColor, sectionTextColor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: parseRgbaColor(sectionBackgroundColor),
    color: parseRgbaColor(sectionTextColor),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: '6px',
  }),
  forwardIcon: {
    height: '14px',
  },
  pageName: ({ typeface }) => ({
    fontFamily: typeface,
    fontSize: '14px',
  }),
}));
