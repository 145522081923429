import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const formValidationSchema = () => Yup.object().shape({
  image: Yup.string().required(),
  title: Yup.string()
    .required(),
  description: Yup.string()
    .required(),
  address: Yup.string(),
  addressLat: Yup.string()
    .required(),
  addressLng: Yup.string()
    .required(),
  email: Yup.string()
    .email(),
  website: Yup.string()
    .matches(REGEXP.URL_WITH_HTTP),
  phoneNumber: Yup.string()
    .matches(REGEXP.PHONE_NUMBER)
    .required(),
  messageLimits: Yup.number()
    .nullable(),
});
