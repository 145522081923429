import React from 'react';
import { Helmet } from 'react-helmet';


export default {
  default: (
    <Helmet
      defaultTitle="ManualPro - Create and publish IFUs in app"
    >
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
      <meta
        name="description"
        content="Create, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software. Use DTC (Direct to Consumer) communications to add value."
      />
    </Helmet>
  ),
  home: (
    <Helmet>
      <title>Publish User Manuals Online</title>
      <meta
        name="description"
        content="Create, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software. Use DTC (Direct to Consumer) communications to add value."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  signUp: (
    <Helmet>
      <title>SignUp - First Product IFU Free</title>
      <meta
        name="description"
        content="Sign Up, create, publish user guides with ManualPro. Use D2C (Direct to Consumer) communications and include added value content like video how to's."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  logIn: (
    <Helmet>
      <title>Login ManualPro</title>
      <meta
        name="description"
        content="Create, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software. Use DTC (Direct to Consumer) communications to add value."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  forgotPassword: (
    <Helmet>
      <title>Reset Password</title>
      <meta
        name="description"
        content="Create Instructions for Use with ManualPro. Forgottten password."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, password, reset, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  aboutUs: (
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="Create, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software. Use DTC (Direct to Consumer) communications to add value."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  support: (
    <Helmet>
      <title>Support 24/7</title>
      <meta
        name="description"
        content="Support, help, chat, create, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software."
      />
      <meta
        name="keywords"
        content="online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  privacy: (
    <Helmet>
      <title>Privacy on ManualPro</title>
      <meta
        name="description"
        content="Privacy, gdpr, data on IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software."
      />
      <meta
        name="keywords"
        content="privacy, data, online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  terms: (
    <Helmet>
      <title>Terms of Use</title>
      <meta
        name="description"
        content="Terms of service, use, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software."
      />
      <meta
        name="keywords"
        content="terms, online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
  gdpr: (
    <Helmet>
      <title>GDPR Policy</title>
      <meta
        name="description"
        content="GDPR, daa, collect, publish and track IFU (Instructions for Use) user guides with ManualPro app publishing and authoring software."
      />
      <meta
        name="keywords"
        content="GDPR, online, app, manual, publish, make, how to, create, write, instructions for use, ifu, authoring, documentation, software, tool, manual, user guide, dtc, direct, consumer, communications, direct to consumer, D2C"
      />
    </Helmet>
  ),
};
