import LinkedInIcon from 'assets/images/social-icons/linkedIn.png';


export const SOCIAL_LINKS = [
  {
    id: 'linkedIn',
    href: 'https://www.linkedin.com/company/manualpro-co/',
    icon: LinkedInIcon,
  },
];
