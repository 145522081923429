import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  Box,
} from '@material-ui/core';

import TrackTraceMessageForm from '@pro/web-common/components/track-trace-message-form';


const ConfigureTrackTraceMessageModal = memo(({ onClose, onSubmit, isSubmitting, isError, productsOptions, ...rest }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="sm"
    fullWidth
    disableBackdropClick={isSubmitting}
    disableEscapeKeyDown={isSubmitting}
  >
    <Box
      pr={3}
    >
      <DialogTitle id="form-dialog-title">
          New Contact Trace Message
      </DialogTitle>

      <Box
        mb={2}
        pt={1}
        pb={1}
        pl={3}
        pr={3}
      >
        <TrackTraceMessageForm
          fetching={isSubmitting}
          isError={isError}
          onSubmit={onSubmit}
          onClose={onClose}
          productsOptions={productsOptions}
          {...rest}
        />
      </Box>
    </Box>
  </Dialog>
));

ConfigureTrackTraceMessageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
};

ConfigureTrackTraceMessageModal.defaultProps = {
  isSubmitting: false,
  isError: false,
};


export default ConfigureTrackTraceMessageModal;
