import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { colorPropsSchema } from '@pro/web-common/utils/style';
import { getPlainTextFromHtml } from '@pro/web-common/utils';

import { styles } from './styles';


const ArchivedPreview = React.memo(({
  typeface,
  issues,
  imprintBackgroundColor,
  imprintFontColor,
  sectionBackgroundColor,
  sectionTextColor,
}) => {
  const classes = styles({ typeface, imprintBackgroundColor, imprintFontColor, sectionBackgroundColor, sectionTextColor });

  return (
    <Box
      className={classes.container}
    >
      {
        issues.map(({ name }) => (
          <Box
            key={name}
            className={classes.section}
          >
            <Typography
              className={classes.sectionText}
            >
              {getPlainTextFromHtml(name)}
            </Typography>

            <ArrowForwardIos className={classes.forwardIcon} />
          </Box>
        ))
      }
    </Box>
  );
});


ArchivedPreview.propTypes = {
  typeface: PropTypes.string.isRequired,
  issues: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  imprintBackgroundColor: colorPropsSchema.isRequired,
  imprintFontColor: colorPropsSchema.isRequired,
  sectionBackgroundColor: colorPropsSchema.isRequired,
  sectionTextColor: colorPropsSchema.isRequired,
};


export default ArchivedPreview;
