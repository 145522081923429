import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
} from '@material-ui/core';

import ProductFilters from '@pro/web-common/components/product-filters';


const GenerateReport = React.memo(({ generatingReportState, onGenerateReport }) => {
  const [filters, setFilters] = useState({
    productId: null,
    fromDate: null,
    toDate: null,
  });

  return (
    <Box>
      <ProductFilters
        onFiltersChange={setFilters}
      />

      <Box
        textAlign="right"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => onGenerateReport({
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            productId: filters.productId,
          })}
          disabled={generatingReportState.fetching}
        >
          Download users personal info
        </Button>
      </Box>
    </Box>
  );
});

GenerateReport.propTypes = {
  generatingReportState: PropTypes.shape({
    fetching: PropTypes.bool,
  }).isRequired,
  onGenerateReport: PropTypes.func.isRequired,
};

GenerateReport.defaultProps = {};


export default GenerateReport;
