import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  editorContainer: {
    flex: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  image: {
    display: 'block',
    width: '100%',
    maxWidth: '130px',
    maxHeight: '130px',
    objectFit: 'contain',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
}));
