import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import CurrencySelector from '@pro/web-common/components/currency-selector-home';

import { DEFAULT_CURRENCY_ID } from 'constants/currency';
import { PLAN_TYPE_SINGLE_ID, PLAN_TYPE_FREE_ID, PLAN_TYPE_MULTI_TEN_ID } from 'constants/subscriptions-config';

import PlanItem from './plan-item';


const PricingSection = React.memo(({ isSignIn }) => {
  const [currencyId, setCurrencyId] = useState(DEFAULT_CURRENCY_ID);

  return (
    <Box
      bgcolor="primary.light"
      pt={8}
      pb={8}
      id="pricing"
    >
      <Container maxWidth="md">
        <Box
          textAlign="center"
          mb={3}
        >
          <Typography
            variant="h3"
          >
            Easy pricing.
          </Typography>
        </Box>

        <Box
          textAlign="center"
          mb={3}
        >
          <CurrencySelector
            currencyId={currencyId}
            onChange={setCurrencyId}
          />
        </Box>

        <Grid
          container
          justify="space-between"
          spacing={8}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="FIRST Product"
              features={[
                '• Single Product FREE',
                '• Fully Branded',
                '• Home Page Gallery',
                '• Create Manual (IFU)',
                '• Auto translate/multi language',
                '• Message Users',
                '• Include Images/Video',
                '• Accessibility Settings',
                '• Text Search',
                '• Add Additional Pages',
                '• QR and Short code incl.',
              ]}
              buttonText="Sign up free"
              opacity={0.7}
              planId={PLAN_TYPE_FREE_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="PER Product"
              features={[
                '• All Single Product features',
                '• Per additional x 1 product',
              ]}
              opacity={0.5}
              planId={PLAN_TYPE_SINGLE_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
          >
            <PlanItem
              title="TEN Products"
              features={[
                '• All Single Product features',
                '• Per additional x 10 products',
              ]}
              opacity={0.7}
              planId={PLAN_TYPE_MULTI_TEN_ID}
              currencyId={currencyId}
              isSignIn={isSignIn}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


PricingSection.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
};

PricingSection.defaultProps = {};


export default PricingSection;
