import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  Typography,
} from '@material-ui/core';

import { withFormik } from 'formik';

import infoJson from 'constants/info';
import InfoSection from '@pro/web-common/components/info-section';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import Select from '@pro/web-common/components/select';
import Checkbox from '@pro/web-common/components/checkbox';
import Radio from '@pro/web-common/components/radio';

import { MESSAGE_TYPE, GEO_TYPES } from '@pro/web-common/core/messages/constants';

import { formValidationSchema, ALL_PRODUCTS_OPTION } from './config';
import { styles } from './styles';


const GEO_VALUES = [
  {
    value: GEO_TYPES.NONE,
    getLabel: () => 'None',
  },
  {
    value: GEO_TYPES.INSIDE,
    getLabel: (radius) => `Within ${radius}km radius`,
  },
  {
    value: GEO_TYPES.OUTSIDE,
    getLabel: (radius) => `Outside ${radius}km radius`,
  },
];

const QuickMessageForm = React.memo(({ fetching, isError, onSubmit, onClose, productsOptions, withAllOption, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = formikProps;

  const [canAddGeo, setCanAddGeo] = useState(true);
  const productsOptionsWithAll = useMemo(() => {
    if (!withAllOption) {
      return productsOptions;
    }

    if (productsOptions) {
      return productsOptions.concat([ALL_PRODUCTS_OPTION]);
    }

    return null;
  }, [withAllOption, productsOptions]);

  const currentProduct = useMemo(() => (productsOptions.find(({ value }) => value === values.productId) || {}), [values.productId]);
  const geoValues = useMemo(() => GEO_VALUES.map(({ getLabel, ...rest }) => ({ ...rest, label: getLabel(currentProduct.pushMessagesRadius) })), [currentProduct]);
  const isSendMessageDisabled = useMemo(() => currentProduct.messageLimits && !currentProduct.availableMessages, [currentProduct]);

  useEffect(() => {
    const isGeoValue = currentProduct && currentProduct.address && currentProduct.address.length > 0 && currentProduct.pushMessagesRadius && currentProduct.pushMessagesRadius.length > 0;

    setFieldValue('isGeo', isGeoValue);
    setCanAddGeo(!!isGeoValue);
  }, [currentProduct]);

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          {
            currentProduct.messageLimits && (
              <Box
                mb={1}
                ml={1}
              >
                <Typography
                  variant="caption"
                  component="p"
                >
                  You can send {currentProduct.messageLimits} messages per {currentProduct.limitMessagePer}
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                >
                  Count of available messages: {currentProduct.availableMessages}
                </Typography>
              </Box>
            )
          }

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="title"
              label="Message text"
              placeholder="Message text"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.title && touched.title}
              autoFocus
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            {productsOptionsWithAll && (
              <InfoSection infoMessage={infoJson.message.audience}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    id="productId"
                    name="productId"
                    label="Select audience"
                    options={productsOptionsWithAll}
                    value={values.productId}
                    onChange={({ target: { value } }) => setFieldValue('productId', value)}
                  />
                </FormControl>
              </InfoSection>
            )}
          </Grid>

          {canAddGeo && (
            <Grid
              item
              xs={12}
            >
              <Typography variant="caption">Geolocation settings</Typography>
              <Radio
                title="Geo settings"
                value={values.geo}
                values={geoValues}
                onChange={({ target: { value } }) => setFieldValue('geo', value)}
                isRow={false}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Box mr={1}>
              <Button
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSendMessageDisabled}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

QuickMessageForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    productsIds: PropTypes.arrayOf(PropTypes.string),
  }),
  productsOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  withAllOption: PropTypes.bool,
};

QuickMessageForm.defaultProps = {
  initialValues: {},
  isError: false,
  withAllOption: true,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, defaultProductId }) => {
    const {
      title = '',
      productsIds,
    } = initialValues || {};

    const initialProductId = productsIds && productsIds.length > 1 ? ALL_PRODUCTS_OPTION.value : null; // if more than 1 product is in the list it means "all" option was selected on creation
    const productId = initialProductId || defaultProductId;
    const values = {
      type: MESSAGE_TYPE.QUICK,
      title,
      productId,
      geo: '',
    };

    return values;
  },
  handleSubmit: ({ productId, ...values }, { props: { onSubmit, productsOptions } }) => {
    let productsIds = [productId];
    let brandId;
    const isAllSelected = productId === ALL_PRODUCTS_OPTION.value;

    if (isAllSelected) {
      productsIds = productsOptions.map(({ value }) => value);
      brandId = productsOptions[0].brandId;
    } else {
      brandId = productsOptions.find(({ value }) => value === productId).brandId;
    }

    onSubmit({
      data: {
        ...values,
        brandId,
        productsIds,
      },
      allProducts: isAllSelected,
    });
  },
  validationSchema: formValidationSchema,
  enableReinitialize: true,
})(QuickMessageForm);
