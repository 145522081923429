import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Box,
  Button,
} from '@material-ui/core';

import Checkbox from '@pro/web-common/components/checkbox';

import { PLAN_TYPES } from 'constants/subscriptions-config';


const ActivateProductsModal = ({ onClose, onSubmit, planTypeId, products: initialProducts }) => {
  const [products, setProducts] = useState(initialProducts);
  const [isMaxProductsSelected, setIsMaxProductsSelected] = useState(false);
  const { availableProducts: productsLimit } = useMemo(() => PLAN_TYPES.find(({ id }) => id === planTypeId), [planTypeId]);

  const setProductSelection = useCallback(({ index, checked }) => {
    const newProductsValues = update(products, {
      [index]: {
        isChecked: { $set: checked },
      },
    });

    setProducts(newProductsValues);
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(() => {
    onSubmit({ productsIds: products.filter(({ isChecked }) => isChecked).map(({ id }) => id) });
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const selectedProductsCount = products.filter(({ isChecked }) => isChecked).length;

    setIsMaxProductsSelected(selectedProductsCount === productsLimit);
  }, [products, productsLimit]);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Resume Subsription
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You can either resume an existing subscription or create new/additional subscriptions. <br />
          Please select an existing product or continue to create a new additional subscription:
        </DialogContentText>

        <Box
          mt={2}
          mb={2}
        >
          <Grid
            container
            spacing={2}
          >
            {products.map(({ id, isChecked, productName }, index) => (
              <Grid
                key={id}
                item
                xs={4}
              >
                <Checkbox
                  id={id}
                  name={id}
                  label={productName}
                  value={isChecked}
                  onChange={({ target: { checked } }) => setProductSelection({
                    index,
                    checked,
                  })}
                  disabled={!isChecked && isMaxProductsSelected}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ActivateProductsModal.propTypes = {
  planTypeId: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ActivateProductsModal.defaultProps = {};


export default ActivateProductsModal;
