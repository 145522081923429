import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

import { Line } from 'react-chartjs-2';


const legendOpts = {
  position: 'bottom',
};

const LineChart = React.memo(({ data, label }) => {
  const hasValues = useMemo(() => data.datasets[0].data.filter((value) => value !== 0).length > 0, [data]);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="body2">{label}</Typography>
      </Box>

      {hasValues ? (
        <Line
          data={data}
          legend={legendOpts}
          options={{
            scales: {
              yAxes: [{
                ticks: {
                  stepSize: 1,
                },
              }],
            },
          }}
        />
      ) : (
        <Typography variant="caption">No visitors for the selected period</Typography>
      )}
    </Box>
  );
});

LineChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  label: PropTypes.string.isRequired,
};


export default LineChart;
