import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Link,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import SectionImage from 'assets/images/home/Manual-Maker-Hero-Free-App-Round.jpg';

import { styles } from './styles';


const StartNowSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      bgcolor="primary.light"
      pt={10}
      pb={10}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h2"
            >
              Start now.
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h1"
              >
                Publish your QR code product manual / instructions for use (IFU) in our free to consumer app.
              </Typography>
            </Box>

            <Typography>
              Reach customers you didn&apos;t know you already have by adding a QR code to your PDFs, website &amp; packaging.<br />
              Create direct to consumer (DTC) communications via added value content, messaging &amp; push notifications.<br />
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Reduce your environmental impact &amp; create a new direct to consumer channel!
              </Box>
            </Typography>

            <Box
              mt={3}
              mb={[4, 0]}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                to="#pricing"
                smooth
                offset={-64}
                component={NavHashLink}
              >
                Pick A Plan
              </Button>

              <Typography component="span">
                Add your first product FREE
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="Instructions for use app (IFU)"
            />

            <Box mt={4}>
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
                ManualPro changes into your own branded app
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


StartNowSection.propTypes = {};

StartNowSection.defaultProps = {};


export default StartNowSection;
