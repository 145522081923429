import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
  IconButton,
  Divider,
  Typography,
} from '@material-ui/core';
import { LocationOn as LocationOnIcon } from '@material-ui/icons';

import { withFormik } from 'formik';

import GoogleAutocomplete from '@pro/web-common/components/google-autocomplete';
import SingleImageUploader from '@pro/web-common/components/single-image-uploader';
import LocationService from '@pro/web-common/services/location';
import Checkbox from '@pro/web-common/components/checkbox';
import Radio from '@pro/web-common/components/radio';
import withScriptLoader from '@pro/web-common/components/with-script-loader';
import InfoSection from '@pro/web-common/components/info-section';

import { LIMIT_MESSAGES_PER_TYPES } from '@pro/web-common/core/profile/constants';

import infoJson from 'constants/info.json';
import config from 'config';

import { formValidationSchema } from './config';

import { styles } from './styles';


const messageLimitsValues = [
  {
    value: LIMIT_MESSAGES_PER_TYPES.DAY,
    label: 'Day',
  },
  {
    value: LIMIT_MESSAGES_PER_TYPES.WEEK,
    label: 'Week',
  },
  {
    value: LIMIT_MESSAGES_PER_TYPES.MONTH,
    label: 'Month',
  },
];
const DirectoryProfileForm = React.memo(({ isAuthorized, isCreator, isOwner, onSubmit, onClose, onError, onResetPassword, isEdit, withRemindPassword, withLimitsConfig, initialValues, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  const onAddressChange = useCallback(async (data) => {
    setFieldValue('address', data.value);

    const { lat, lng } = await LocationService.getLocationLatLng(data.value);

    setFieldValue('addressLat', lat !== null ? lat : '');
    setFieldValue('addressLng', lng !== null ? lng : '');
  }, [setFieldValue]);

  const onPinLocation = useCallback(async () => {
    const calcUserLocation = await LocationService.getUserLocation();

    if (calcUserLocation) {
      const { latitude, longitude } = calcUserLocation;
      const geocodedAddress = await LocationService.geocodeAddress(latitude, longitude);

      setFieldValue('address', geocodedAddress);
      setFieldValue('addressLat', latitude);
      setFieldValue('addressLng', longitude);
    } else {
      onError({ errors: ['Sorry, we cannot detect your location'] });
    }
  }, [onError, setFieldValue]);

  const onRemindPassword = useCallback(() => {
    onResetPassword(values.email);
  }, [onResetPassword, values.email]);

  const handleImageAdd = useCallback((fileData) => setFieldValue('image', fileData), [setFieldValue]);
  const handleImageDelete = useCallback(() => setFieldValue('image', null), [setFieldValue]);

  const withRemindPasswordButton = isEdit && withRemindPassword && !isAuthorized && initialValues?.email;
  const isEditDisabled = !isCreator && !isOwner;
  const isEmailEditDisabled = !!(isEdit && !isEditDisabled && initialValues?.email);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className={classes.container}>
          {
            isEditDisabled ? (
              <img
                src={values.image?.filePath}
                alt="profile"
                className={classes.image}
              />
            ) : (
              <SingleImageUploader
                image={values.image}
                error={errors.image && touched.image}
                imagePreviewHeight={130}
                imagePreviewWidth={130}
                recommendedSize={{
                  height: 130,
                  width: 130,
                }}
                onImageAdd={handleImageAdd}
                onImageDelete={handleImageDelete}
              />
            )
          }

          <Box
            className={classes.editorContainer}
            pr={3}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <InfoSection
                  infoMessage={infoJson.product.profile.name}
                >
                  <TextField
                    autoFocus
                    variant="outlined"
                    fullWidth
                    id="title"
                    label="Profile Name"
                    placeholder="Profile Name"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.title && touched.title}
                    InputProps={{
                      disabled: isEditDisabled,
                    }}
                  />
                </InfoSection>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection
                  infoMessage={infoJson.product.profile.description}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="description"
                    label="Description"
                    placeholder="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.description && touched.description}
                    InputProps={{
                      disabled: isEditDisabled,
                    }}
                  />
                </InfoSection>

              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection
                  infoMessage={infoJson.product.profile.address}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    flex={1}
                  >
                    <GoogleAutocomplete
                      variant="outlined"
                      fullWidth
                      id="address"
                      label="Address"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={(data) => data ? onAddressChange(data) : null}
                      onBlurEvent={handleBlur}
                      error={errors.address && touched.address}
                      InputProps={{
                        disabled: isEditDisabled,
                      }}
                    />

                    <Box
                      display={{
                        xs: 'block',
                        sm: 'none',
                      }}
                      ml={2}
                    >
                      <IconButton onClick={onPinLocation}>
                        <LocationOnIcon color="primary" />
                      </IconButton>
                    </Box>
                  </Box>
                </InfoSection>
              </Grid>

              <Grid
                item
                container
                xs={12}
              >
                <Grid
                  item
                  xs={6}
                >
                  <Box mr={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="addressLat"
                      label="Latitude"
                      placeholder="Latitude"
                      name="addressLat"
                      value={values.addressLat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.addressLat && touched.addressLat}
                      InputProps={{
                        disabled: isEditDisabled,
                      }}
                    />
                  </Box>

                </Grid>

                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="addressLng"
                    label="Longitude"
                    placeholder="Longitude"
                    name="addressLng"
                    value={values.addressLng}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.addressLng && touched.addressLng}
                    InputProps={{
                      disabled: isEditDisabled,
                    }}
                  />
                </Grid>

                <InfoSection
                  infoMessage={infoJson.product.profile.latLng}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm
                  >
                    <Box pr={withRemindPasswordButton ? 4 : 0}>
                      <InfoSection
                        infoMessage={infoJson.product.profile.email}
                      >
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="email"
                          label="Email"
                          placeholder="Email"
                          name="email"
                          autoComplete="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email && touched.email}
                          disabled={isEmailEditDisabled}
                        />
                      </InfoSection>
                    </Box>
                  </Grid>

                  {withRemindPasswordButton && (
                    <Grid item>
                      <Box>
                        <InfoSection
                          infoMessage={infoJson.product.profile.password}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onRemindPassword}
                          >
                            Remind password
                          </Button>
                        </InfoSection>
                      </Box>

                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection
                  infoMessage={infoJson.product.profile.phoneNumber}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="phoneNumber"
                    label="Phone number"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    autoComplete="tel"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phoneNumber && touched.phoneNumber}
                    InputProps={{
                      disabled: isEditDisabled,
                    }}
                  />
                </InfoSection>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <InfoSection
                  infoMessage={infoJson.product.profile.website}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="website"
                    label="Website"
                    placeholder="Website"
                    name="website"
                    value={values.website}
                    autoComplete="new-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.website && touched.website}
                    InputProps={{
                      disabled: isEditDisabled,
                    }}
                  />
                </InfoSection>
              </Grid>

              {withLimitsConfig && (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Box
                      mt={2}
                      mb={2}
                    >
                      <Divider variant="middle" />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography>Message limits</Typography>
                  </Grid>

                  <Grid
                    item
                  >
                    <InfoSection
                      infoMessage={infoJson.product.profile.limitNumber}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="messageLimits"
                        label="How many messages"
                        placeholder="100"
                        name="messageLimits"
                        value={values.messageLimits}
                        autoComplete="new-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.messageLimits && touched.messageLimits}
                      />
                    </InfoSection>

                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    What to limit:
                  </Grid>

                  <Grid
                    item
                  >
                    <InfoSection
                      infoMessage={infoJson.product.profile.limitType}
                    >
                      <Box mb={1}>
                        <Checkbox
                          id="limitImageMessage"
                          name="limitImageMessage"
                          value={values.limitImageMessage}
                          label="Image Message"
                          onChange={({ target: { checked } }) => setFieldValue('limitImageMessage', checked)}
                          onBlur={handleBlur}
                        />

                        <Checkbox
                          id="limitQuickMessage"
                          name="limitQuickMessage"
                          value={values.limitQuickMessage}
                          label="Quick Message"
                          onChange={({ target: { checked } }) => setFieldValue('limitQuickMessage', checked)}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </InfoSection>

                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    Limit per:
                  </Grid>

                  <Grid
                    item
                  >
                    <InfoSection
                      infoMessage={infoJson.product.profile.limitPer}
                    >
                      <Radio
                        title="Limit per"
                        value={values.limitMessagePer}
                        values={messageLimitsValues}
                        onChange={({ target: { value } }) => setFieldValue('limitMessagePer', value)}
                      />
                    </InfoSection>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
              >
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  {onClose && (
                    <Box mr={1}>
                      <Button
                        color="primary"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isEdit ? 'Save' : 'Add'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </Box>
  );
});


DirectoryProfileForm.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func,
  isEdit: PropTypes.bool,
  withRemindPassword: PropTypes.bool,
  withLimitsConfig: PropTypes.bool,
  isOwner: PropTypes.bool,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

DirectoryProfileForm.defaultProps = {
  isEdit: false,
  withRemindPassword: false,
  withLimitsConfig: false,
  onClose: null,
  onResetPassword: () => null,
  isCreator: false,
  isOwner: false,
};


const ComponentWithScriptLoader = withScriptLoader(DirectoryProfileForm, { scriptUrl: config.google.apiUrl });
export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      image = null,
      title = '',
      description = '',
      address = '',
      addressLat = '',
      addressLng = '',
      email = '',
      website = '',
      phoneNumber = '',
      messageLimits = '',
      limitImageMessage = false,
      limitQuickMessage = false,
      limitMessagePer = messageLimitsValues[0].value,
    } = initialValues || {};

    const values = {
      image,
      title,
      description,
      address,
      addressLat,
      addressLng,
      email,
      website,
      phoneNumber,
      messageLimits,
      limitImageMessage,
      limitQuickMessage,
      limitMessagePer,
    };

    return values;
  },
  handleSubmit: (values, { props: { directoryIndex, onSubmit, initialValues, isCreator } }) => {
    onSubmit({
      ...initialValues,
      ...values,
      ...(values.addressLat ? { addressLat: parseFloat(values.addressLat) } : {}),
      ...(values.addressLng ? { addressLng: parseFloat(values.addressLng) } : {}),
      canUpdate: isCreator,
    }, directoryIndex);
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(ComponentWithScriptLoader);
