import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';

import DirectoryForm from '@pro/web-common/components/directory-form';


const ConfigureDirectoryModal = React.memo(({ isEdit, onClose, onSubmit, initialValues }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      {isEdit ? 'Edit' : 'Add'}
      {' '}
      Directory
    </DialogTitle>

    <DialogContent>
      <Box mb={2}>
        <DirectoryForm
          isEdit={isEdit}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </Box>
    </DialogContent>
  </Dialog>
));


ConfigureDirectoryModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ConfigureDirectoryModal.defaultProps = {
  isEdit: false,
  initialValues: null,
};


export default ConfigureDirectoryModal;
