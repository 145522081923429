const config = {
  firebase: {
    apiKey: 'AIzaSyDMOw4-978IdGVckGlYB5lyBiOLpUVtbn0',
    authDomain: 'manual-pro-dev.firebaseapp.com',
    databaseURL: 'https://manual-pro-dev.firebaseio.com',
    projectId: 'manual-pro-dev',
    storageBucket: 'manual-pro-dev.appspot.com',
    messagingSenderId: '457123305545',
    appId: '1:457123305545:web:60bcc189fd607d4e5d8798',
    measurementId: 'G-2D6J9M3RQN',
  },
  stripePublicAPIKey: 'pk_test_yxxlBuP3yFzQokRVnwJU7OMQ00f1ltv0xv',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA7C0tI8twBRdty4kvtD_FhT_-9ae0aUEc&libraries=places,geometry',
  },
};


export default config;
