import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { formValidationSchema } from './config';


const ContactDirectoryForm = React.memo(({ onSubmit, onClose, isEdit, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formikProps;
  // TODO: show form errors
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              id="contactName"
              label="Contact Name"
              placeholder="Contact Name"
              name="contactName"
              InputLabelProps={{ shrink: true }}
              value={values.contactName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.contactName && touched.contactName}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email"
              name="email"
              InputLabelProps={{ shrink: true }}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.email || errors.emailOrPhone) && touched.email}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="phone"
              label="Telephone Number"
              placeholder="Telephone Number"
              name="phone"
              InputLabelProps={{ shrink: true }}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.phone || errors.emailOrPhone) && touched.phone}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Box mr={1}>
                <Button
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});


ContactDirectoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

ContactDirectoryForm.defaultProps = {
  isEdit: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      contactName = '',
      email = '',
      phone = '',
    } = initialValues || {};

    const values = {
      contactName,
      email,
      phone,
    };

    return values;
  },
  handleSubmit: (data, { props: { initialValues, onSubmit } }) => {
    const { index } = initialValues || {};

    onSubmit(index, { ...data });
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(ContactDirectoryForm);
