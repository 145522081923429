import { v4 } from 'uuid';
import { find, findIndex, propEq } from 'ramda';


export const createConstants = (constants, constantType) => constants.reduce((acc, constant) => {
  acc[constant] = `${constantType}/${constant}`;
  return acc;
}, {});
export const generateId = () => v4();
export const findIndexByProp = (array, key, value) => findIndex(propEq(key, value))(array);
export const findElementByProp = (array, key, value) => find(propEq(key, value))(array);
export const getPlainTextFromHtml = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');
