import { formatDateForChart, filterListByDatesRange } from '@pro/web-common/utils/date';


const IOS_DATA_COLOR = '#FF6384';
const ANDROID_DATA_COLOR = '#FFCE56';
const LINE_CHART_BG_COLOR = 'rgba(75, 192, 192, 0.4)';
const LINE_CHART_BORDER_COLOR = 'rgba(75, 192, 192, 1)';
const LINE_CHART_POINT_BORDER_COLOR = 'rgba(75, 192, 192, 1)';
const LINE_CHART_POINT_BG_COLOR = '#FFF';
const LINE_CHART_POINT_HOVER_BG_COLOR = 'rgba(75, 192, 192, 1)';
const LINE_CHART_POINT_HOVER_BORDER_COLOR = 'rgba(220, 220, 220, 1)';

const pieChartProps = {
  labels: [
    'IOS visitors',
    'Android visitors',
  ],
  datasets: [
    {
      backgroundColor: [
        IOS_DATA_COLOR,
        ANDROID_DATA_COLOR,
      ],
      hoverBackgroundColor: [
        IOS_DATA_COLOR,
        ANDROID_DATA_COLOR,
      ],
    },
  ],
};

const lineChartProps = {
  datasets: [
    {
      fill: false,
      lineTension: 0.1,
      backgroundColor: LINE_CHART_BG_COLOR,
      borderColor: LINE_CHART_BORDER_COLOR,
      borderCapStyle: 'butt',
      borderDash: [],
      borderJoinStyle: 'miter',
      pointBorderColor: LINE_CHART_POINT_BORDER_COLOR,
      pointBackgroundColor: LINE_CHART_POINT_BG_COLOR,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: LINE_CHART_POINT_HOVER_BG_COLOR,
      pointHoverBorderColor: LINE_CHART_POINT_HOVER_BORDER_COLOR,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      borderDashOffset: 10,
    },
  ],
};

const getYearsFromData = (data) => data.reduce((acc, { date }) => {
  const year = date.split('/')[2];

  return {
    ...acc,
    [year]: true,
  };
}, {});

export const getPeriodData = (data = [], fromDate, toDate) => {
  const transformedToDate = new Date(toDate);
  transformedToDate.setDate(transformedToDate.getDate() + 1);

  const periodData = filterListByDatesRange(new Date(fromDate), new Date(transformedToDate), 'date')(data);

  return periodData;
};

export const generateLineChartProps = (periodData = [], isTotalViews) => {
  const numberOfYears = Object.entries(getYearsFromData(periodData)).length;
  const withYearsLabel = numberOfYears > 1;

  const claculatedData = (
    periodData.reduce((acc, { date, uniqueViews, totalViews }) => {
      const label = formatDateForChart(date, withYearsLabel);
      const viewsData = isTotalViews ? totalViews : uniqueViews;

      return {
        labels: [...acc.labels, label],
        data: [...acc.data, viewsData.ios + viewsData.android],
      };
    }, {
      labels: [],
      data: [],
    })
  );

  const { labels, data } = claculatedData;

  return ({
    labels,
    datasets: [
      {
        ...lineChartProps.datasets[0],
        label: isTotalViews ? 'Number of total visits' : 'Number of unique visitors',
        data,
      },
    ],
  });
};

export const generatePieChartProps = (periodData = [], isTotalViews) => {
  const { iosViews: iosValue, androidViews: androidValue } = periodData.reduce((acc, { uniqueViews, totalViews }) => {
    const viewsData = isTotalViews ? totalViews : uniqueViews;

    return ({
      iosViews: acc.iosViews + viewsData.ios,
      androidViews: acc.androidViews + viewsData.android,
    });
  }, {
    iosViews: 0,
    androidViews: 0,
  });

  return ({
    ...pieChartProps,
    datasets: [{
      ...pieChartProps.datasets[0],
      data: [iosValue, androidValue],
    }],
  });
};

export const getTotalNumberOfUsers = (periodData = [], isTotalViews) => periodData.reduce((acc, { uniqueViews, totalViews }) => {
  const viewsData = isTotalViews ? totalViews : uniqueViews;

  return acc + viewsData.ios + viewsData.android;
}, 0);
