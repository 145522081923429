import React from 'react';

import {
  Container,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';

import Home from 'assets/images/app-screenshots/Manual_Maker_App_Screen.png';
import Product from 'assets/images/app-screenshots/Manual_Maker_App_Screen_Product.png';
import Messages from 'assets/images/app-screenshots/Manual_Maker_App_Push_Messaging.png';
import Manual from 'assets/images/app-screenshots/Manual_Maker_App_Translation_Product.png';


const ScreenshotsSection = React.memo(() => (
  <Box
    bgcolor="primary.main"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <img
            src={Home}
            alt="Create your own product manual app"
          />
        </Grid>

        <Hidden only="xs">
          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Product}
              alt="Add as many products as you wish"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Manual}
              alt="Incluse images, text and video"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Messages}
              alt="Contact your customers direct"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  </Box>
));


ScreenshotsSection.propTypes = {};

ScreenshotsSection.defaultProps = {};


export default ScreenshotsSection;
