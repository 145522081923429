import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import Tooltip from '@pro/web-common/components/conditional-tooltip';

import { styles } from './styles';


const ImageWithPlaceholder = React.memo(({ image, width, height, onDelete, error, recommendedSize, recommendedMinWidth, recommendedMaxWidth, withFit }) => {
  const [warningText, setWarningText] = useState(null);
  const classes = styles({ withFit, withImage: !!image, width, height, error });

  const onImageLoad = useCallback(({ target: { naturalWidth, naturalHeight } }) => {
    let message = null;

    if (recommendedSize) {
      const { height: recommendedHeight, width: recommendedWidth } = recommendedSize;

      if (recommendedHeight !== naturalHeight && recommendedWidth !== naturalWidth) {
        message = `Recommended image size is ${recommendedWidth}x${recommendedHeight}px`;
      }
    } else {
      if (naturalWidth < recommendedMinWidth) {
        message = `Recommended image width is min ${recommendedMinWidth}px`;
      }

      if (naturalWidth > recommendedMaxWidth) {
        message = `Recommended image width is max ${recommendedMaxWidth}px`;
      }
    }

    setWarningText(message);
  }, [recommendedMaxWidth, recommendedMinWidth, recommendedSize]);

  return (
    <Box className={classes.container}>
      {image ? (
        <img
          className={classes.image}
          onLoad={onImageLoad}
          src={image}
          alt="preview"
        />
      ) : (
        <Typography className={classes.labelText}>
          Upload image (Rec. 2208px wide, max 500k)
        </Typography>
      )}

      {warningText && (
        <Box
          className={classes.warningContainer}
        >
          <Tooltip
            title={warningText}
            isShown
          >
            <WarningIcon
              fontSize="small"
              color="secondary"
            />
          </Tooltip>
        </Box>
      )}

      {image && onDelete && (
        <Box
          className={classes.iconContainer}
          onClick={onDelete}
        >
          <DeleteIcon
            fontSize="small"
            color="secondary"
          />
        </Box>
      )}
    </Box>
  );
});

ImageWithPlaceholder.propTypes = {
  image: PropTypes.string,
  onDelete: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  error: PropTypes.bool,
  recommendedMinWidth: PropTypes.number,
  recommendedMaxWidth: PropTypes.number,
  recommendedSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  withFit: PropTypes.bool,
};

ImageWithPlaceholder.defaultProps = {
  image: null,
  onDelete: null,
  width: 75,
  height: 100,
  error: false,
  recommendedMinWidth: 100,
  recommendedMaxWidth: 767,
  recommendedSize: null,
  withFit: false,
};


export default ImageWithPlaceholder;
