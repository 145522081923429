import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const TimePicker = React.memo(({ value, fullWidth, ...rest }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardTimePicker
      variant="inline"
      inputVariant="outlined"
      placeholder="hh:mm"
      value={value}
      InputLabelProps={{ shrink: true }}
      fullWidth
      {...rest}
    />
  </MuiPickersUtilsProvider>
));

TimePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  fullWidth: PropTypes.bool,
};

TimePicker.defaultProps = {
  value: null,
  fullWidth: false,
};


export default TimePicker;
