import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Container, Grid, Typography, Box, Button } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import userActions from '@pro/web-common/core/user/actions';

import { styles } from './styles';


const DemoIntro = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Check out our demo!"
        subtitle="Watch how consumers download your IFU / Product Manuals right on their phones, then try our app editor for yourself."
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="Just add the QR to your packaging, inside or out."
            subtitle="No need for printed manuals or searching the web for outdated and hard to read PDFs."
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/1nUOmu7o2NM"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            You can add the QR code to your packaging outside or inside the box. Consumers just scan the QR code. This video shows what happens even if they don&apos;t have
            ManualPro app already - it automatically downloads, opens at your product and favourites it for later!
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="No code app ... try out our app editor!"
            subtitle="You can set up your brand and add a product. Watch the video and try for yourself."
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/krwbLR7VPBw"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            Try out our editor! You can see how easy it is to set up your brand, choose colours and font - and add your first product.
            It&apos;s just a quick look at the admin. your first product is FREE, so why not sign up?
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
              ml={2}
              mr={2}
            >
              <Button
                component={NavLink}
                color="inherit"
                variant="outlined"
                to="/configure-brand"
              >
                Demo Admin
              </Button>
            </Box>

            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

DemoIntro.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoIntro.defaultProps = {};

const mapDispatchToProps = {
  signOut: userActions.signOut,
};


export default connect(null, mapDispatchToProps)(DemoIntro);
