import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: ({ imprintBackgroundColor, imprintFontColor }) => ({
    color: parseRgbaColor(imprintFontColor),
    backgroundColor: parseRgbaColor(imprintBackgroundColor),
    padding: theme.spacing(1),
    minHeight: '100%',
    height: '100%',
  }),
  section: ({ sectionBackgroundColor, sectionTextColor }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: parseRgbaColor(sectionBackgroundColor),
    color: parseRgbaColor(sectionTextColor),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: '6px',
  }),
  sectionText: ({ typeface }) => ({
    fontFamily: typeface,
    fontSize: '14px',
  }),
  forwardIcon: {
    height: '14px',
  },
}));
