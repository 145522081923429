import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
} from '@material-ui/core';

import SectionTitle from '@pro/web-common/components/section-title';
import ConfigureQuickMessageModal from '@pro/web-common/components/configure-quick-message-modal';
import ConfigureTrackTraceMessageModal from '@pro/web-common/components/configure-track-trace-message-modal';
import InfoSection from '@pro/web-common/components/info-section';
import QuickMessageListingItem from '@pro/web-common/components/quick-message-listing-item';
import TrackTraceMessageListingItem from '@pro/web-common/components/track-trace-message-listing-item';
import PaginatedList from '@pro/web-common/components/paginated-list';
import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';

import { UserRoleContext } from '@pro/web-common/containers/providers';

import { QUICK_MESSAGES_LISTING_NO_DATA_TEXT } from 'content/texts';
import infoJson from 'constants/info';
import { PAGE_TYPE } from 'constants/product-config';


const QuickMessagesListing = React.memo(({ messages, productsOptions, creatingState: { fetching: isCreating, error: messageCreationError }, onMessageAdd, disabled, selectedProductId, onQuickMessageDelete, onTrackTraceMessageDelete, profiles }) => {
  const { isProfile } = useContext(UserRoleContext);

  const [isConfigureQuickMessageModalOpened, setIsConfigureQuickMessageModalOpened] = useState(false);
  const [isConfigureTrackTraceMessageModalOpened, setIsConfigureTrackTraceMessageModalOpened] = useState(false);

  const isTrackAndTraceMessageAvailable = useMemo(() => !!PAGE_TYPE.MENU && !isProfile, []);

  useEffect(() => {
    if (!isCreating && !messageCreationError) {
      setIsConfigureQuickMessageModalOpened(false);
      setIsConfigureTrackTraceMessageModalOpened(false);
    }
  }, [isCreating, messageCreationError]);

  return (
    <>
      <SectionTitle title="Quick Messages" />

      <PaginatedList
        data={messages}
        noDataText={QUICK_MESSAGES_LISTING_NO_DATA_TEXT}
        renderListingItem={({ data: { id, fetching, type, profileId, ...rest } }) => type === MESSAGE_TYPE.TRACK_TRACE ? (
          <TrackTraceMessageListingItem
            key={id}
            onDelete={() => onTrackTraceMessageDelete({ id })}
            isDeleting={fetching}
            {...rest}
          />
        ) : (
          <QuickMessageListingItem
            key={id}
            onDelete={() => onQuickMessageDelete({ id })}
            isDeleting={fetching}
            profileId={profileId}
            profile={profiles[profileId]}
            {...rest}
          />
        )}
      />

      <Box
        textAlign="right"
        mt={2}
        pr={5}
      >
        <InfoSection
          infoMessage={infoJson.message.quick}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsConfigureQuickMessageModalOpened(true)}
            disabled={disabled}
          >
            New quick message
          </Button>
        </InfoSection>
      </Box>

      {isTrackAndTraceMessageAvailable && (
        <Box
          textAlign="right"
          mt={2}
          pr={5}
        >
          <InfoSection
            infoMessage={infoJson.message.trackTrace}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsConfigureTrackTraceMessageModalOpened(true)}
              disabled={disabled}
            >
              New Contact Trace message
            </Button>
          </InfoSection>
        </Box>
      )}

      {isConfigureQuickMessageModalOpened && (
        <ConfigureQuickMessageModal
          defaultProductId={selectedProductId}
          onClose={() => setIsConfigureQuickMessageModalOpened(false)}
          onSubmit={onMessageAdd}
          productsOptions={productsOptions}
          isSubmitting={isCreating}
          isError={!!messageCreationError}
        />
      )}

      {isConfigureTrackTraceMessageModalOpened && (
        <ConfigureTrackTraceMessageModal
          defaultProductId={selectedProductId}
          onClose={() => setIsConfigureTrackTraceMessageModalOpened(false)}
          onSubmit={onMessageAdd}
          productsOptions={productsOptions}
          isSubmitting={isCreating}
          isError={!!messageCreationError}
        />
      )}
    </>
  );
});

QuickMessagesListing.propTypes = {
  productsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  creatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  onMessageAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedProductId: PropTypes.string,
  onQuickMessageDelete: PropTypes.func.isRequired,
  onTrackTraceMessageDelete: PropTypes.func.isRequired,
  profiles: PropTypes.shape({}).isRequired,
};

QuickMessagesListing.defaultProps = {
  disabled: false,
  selectedProductId: null,
};


export default QuickMessagesListing;
