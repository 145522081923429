import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { UserRoleContext } from '@pro/web-common/containers/providers';


import { styles } from './styles';


const QuickMessageListingItem = React.memo(({ title, date, onDelete, isDeleting, profile, profileId }) => {
  const classes = styles();
  const { isProfile } = useContext(UserRoleContext);

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Typography
          variant="body2"
          gutterBottom
        >
          {title}
        </Typography>

        <Typography
          variant="caption"
          component="p"
        >
          Sent {date}
        </Typography>
        {
          profileId && !isProfile && (
            <Typography
              variant="caption"
              component="p"
            >
              Sent by {profile ? profile.title : 'Local Profile'}
            </Typography>
          )
        }
      </Box>

      {
        !isProfile && (
          <IconButton
            onClick={onDelete}
            disabled={isDeleting}
          >
            <DeleteIcon color="secondary" />
          </IconButton>
        )
      }
    </Box>
  );
});

QuickMessageListingItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  profile: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  profileId: PropTypes.string,
};

QuickMessageListingItem.defaultProps = {
  isDeleting: false,
  profile: null,
  profileId: null,
};


export default QuickMessageListingItem;
