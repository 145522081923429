import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';

import {
  Box,
  Button,
  Collapse,
} from '@material-ui/core';


import ConfigureDirectoryModal from '@pro/web-common/components/configure-directory-modal';
import ConfigureDirectoryProfileModal from '@pro/web-common/components/configure-directory-profile-modal';
import ExistingDirectoryProfileModal from '@pro/web-common/components/existing-directory-profile-modal';
import DirectoriesListing from '@pro/web-common/components/directories-listing';
import { ProductIdContext } from '@pro/web-common/containers/providers';

import { actions as profileActions } from '@pro/web-common/core/profile/actions';
import { actions as userActions } from '@pro/web-common/core/user/actions';
import { getProfilesCollectionWithLimits } from '@pro/web-common/core/profile/selectors';
import { getBrandId } from '@pro/web-common/core/user/selectors';
import { asyncAction } from '@pro/web-common/core/actions';

import { generateId } from '@pro/mobile-common/utils';


const LocalDirectoryPageEditor = React.memo(({ isOpened, initialValues: { directories }, onSubmit, onError, sendResetPasswordLink, getProfileByEmail, brandId, createProfile, updateProfile, profiles }) => {
  const productId = useContext(ProductIdContext);

  const [directoriesData, setDirectoriesData] = useState(directories || []);
  const [isConfigureDirectoryModalOpen, setIsConfigureDirectoryModalOpen] = useState(false);
  const [isConfigureProfileModalOpen, setIsConfigureProfileModalOpen] = useState(false);
  const [directoryModalData, setDirectoryModalData] = useState(null);
  const [profileModalData, setProfileModalData] = useState(null);
  const [profileModalDirectoryIndex, setProfileModalDirectoryIndex] = useState(null);
  const [existingProfile, setExistingProfile] = useState(null);

  const editDirectory = useCallback((data) => {
    setDirectoryModalData(data);
    setIsConfigureDirectoryModalOpen(true);
  }, []);

  const editProfile = useCallback((profileId, directoryIndex) => {
    setProfileModalData({ ...profiles[profileId], isExist: true });
    setProfileModalDirectoryIndex(directoryIndex);
    setIsConfigureProfileModalOpen(true);
  }, [profiles]);

  useEffect(() => {
    if (!isConfigureDirectoryModalOpen) {
      setDirectoryModalData(null);
    }
  }, [isConfigureDirectoryModalOpen]);

  useEffect(() => {
    if (!isConfigureProfileModalOpen) {
      setProfileModalData(null);
    }
  }, [isConfigureProfileModalOpen]);

  const handleDirectoryUpdate = useCallback((index, data) => {
    if (index !== undefined) {
      setDirectoriesData(update(directoriesData, { [index]: { $merge: data } }));
    } else {
      setDirectoriesData(update(directoriesData, { $push: [{ ...data, profiles: [] }] }));
    }

    setIsConfigureDirectoryModalOpen(false);
  }, [directoriesData]);

  const onUpdateProfile = useCallback(async (profile, directoryIndex) => {
    if (profile.email && (profiles[profile.profileId] && !profiles[profile.profileId].email)) {
      try {
        const existingProfileFromDB = await asyncAction(getProfileByEmail, { email: profile.email, productId });
        if (existingProfileFromDB) {
          setExistingProfile(existingProfileFromDB);
          return;
        }
      } catch (e) {
        return;
      }
    }

    if (profile.addToDirectory) {
      const prevProfiles = directoriesData[directoryIndex].profiles || [];
      const newProfiles = update(prevProfiles, { $push: [profile.profileId] });
      const newDirectory = {
        ...directoriesData[directoryIndex],
        profiles: newProfiles,
      };

      const newDirectories = update(directoriesData, { [directoryIndex]: { $set: newDirectory } });

      setDirectoriesData(newDirectories);
    }

    updateProfile({ profile, productId });
    setIsConfigureProfileModalOpen(false);
  }, [directoriesData, productId, updateProfile, profiles]);

  const onCreateProfile = useCallback(async (profile, directoryIndex) => {
    try {
      if (profile.email) {
        const existingProfileFromDB = await asyncAction(getProfileByEmail, { email: profile.email, productId });
        if (existingProfileFromDB) {
          setExistingProfile(existingProfileFromDB);
          return;
        }
      }

      const profileId = generateId();
      await asyncAction(createProfile, { profile, profileId, productId });
      const prevProfiles = directoriesData[directoryIndex].profiles || [];
      const newProfiles = update(prevProfiles, { $push: [profileId] });
      const newDirectory = {
        ...directoriesData[directoryIndex],
        profiles: newProfiles,
      };

      const newDirectories = update(directoriesData, { [directoryIndex]: { $set: newDirectory } });

      setDirectoriesData(newDirectories);
      setIsConfigureProfileModalOpen(false);
    } catch (e) {
      // TODO: handle?
    }
  }, [createProfile, directoriesData, productId]);

  const handleProfileUpdate = useCallback((profile, directoryIndex) => {
    if (profile.isExist) {
      onUpdateProfile(profile, directoryIndex);
    } else {
      onCreateProfile(profile, directoryIndex);
    }
  }, [onUpdateProfile, onCreateProfile]);

  const handleDirectoryDelete = useCallback((index) => {
    setDirectoriesData(update(directoriesData, { $splice: [[index, 1]] }));
  }, [directoriesData]);

  const handleDirectoriesUpdate = useCallback((data) => {
    setDirectoriesData(data);
  }, []);

  const onAddDirectory = useCallback(() => setIsConfigureDirectoryModalOpen(true), []);
  const onAddProfile = useCallback((directoryIndex) => {
    setProfileModalDirectoryIndex(directoryIndex);
    setIsConfigureProfileModalOpen(true);
  }, []);

  const onCancelExistingProfile = useCallback(() => {
    setExistingProfile(null);
  }, []);

  const onSubmitExistingProfile = useCallback(() => {
    setProfileModalData({ ...existingProfile, profileId: existingProfile.id, isExist: true, addToDirectory: true });
    setExistingProfile(null);
  }, [existingProfile]);

  const onResetPassword = useCallback((email) => {
    sendResetPasswordLink({ email, isProfile: true });
  }, []);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box
        mt={2}
        mb={2}
      >
        <DirectoriesListing
          directories={directoriesData}
          profilesList={profiles}
          onEdit={editDirectory}
          onDelete={handleDirectoryDelete}
          onUpdate={handleDirectoriesUpdate}
          onAddProfile={onAddProfile}
          onEditProfile={editProfile}
        />

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onAddDirectory}
          >
            Add Directory
          </Button>
        </Box>

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit({ directories: directoriesData })}
          >
            Save
          </Button>
        </Box>
      </Box>

      {isConfigureDirectoryModalOpen && (
        <ConfigureDirectoryModal
          isEdit={!!directoryModalData}
          initialValues={directoryModalData}
          onClose={() => setIsConfigureDirectoryModalOpen(false)}
          onSubmit={handleDirectoryUpdate}
        />
      )}

      {isConfigureProfileModalOpen && (
        <ConfigureDirectoryProfileModal
          isAuthorized={!!profileModalData && profileModalData.isAuthorized}
          isCreator={profileModalData ? profileModalData.creatorBrandId === brandId : true}
          isEdit={!!profileModalData}
          initialValues={profileModalData}
          directoryIndex={profileModalDirectoryIndex}
          onClose={() => setIsConfigureProfileModalOpen(false)}
          onSubmit={handleProfileUpdate}
          onError={onError}
          onResetPassword={onResetPassword}
        />
      )}

      {existingProfile && (
        <ExistingDirectoryProfileModal
          profile={existingProfile}
          onCancel={onCancelExistingProfile}
          onSubmit={onSubmitExistingProfile}
        />
      )}
    </Collapse>
  );
});


LocalDirectoryPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    directories: PropTypes.arrayOf(PropTypes.shape({
      directoryName: PropTypes.string.isRequired,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  sendResetPasswordLink: PropTypes.func.isRequired,
  getProfileByEmail: PropTypes.func.isRequired,
  brandId: PropTypes.string.isRequired,
  createProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profiles: PropTypes.shape({}),
};

LocalDirectoryPageEditor.defaultProps = {
  profiles: {},
};

const mapsStateToProps = (state) => ({
  brandId: getBrandId(state),
  profiles: getProfilesCollectionWithLimits(state),
});

const mapDispatchToProps = {
  sendResetPasswordLink: userActions.sendResetPasswordLink,
  getProfileByEmail: profileActions.getProfileByEmail,
  createProfile: profileActions.createProfile,
  updateProfile: profileActions.updateProfile,
};


export default connect(mapsStateToProps, mapDispatchToProps)(LocalDirectoryPageEditor);
