import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getFirestoreTimestampDate } from '@pro/web-common/utils/date';
import EmptyContentPreview from '@pro/web-common/components/empty-content-preview';

import IssuePreview from './issue-preview';
import ImprintPreview from './imprint-preview';
import ArchivedPreview from './archived-preview';
import PagePreview from './page-preview';


const MagazinePageContentPreview = React.memo(({ config, issues, useFilteredIssues, showImprintPage, showPage }) => {
  const visibleIssues = useMemo(() => {
    if (!useFilteredIssues) {
      return issues;
    }

    if (!issues.length) {
      return [];
    }

    const startOfToday = new Date();
    startOfToday.setUTCHours(0, 0);
    return issues.filter(({ startDate }) => {
      if (!startDate) {
        return true;
      }

      return getFirestoreTimestampDate(startDate).getTime() < startOfToday.getTime();
    });
  }, [issues, useFilteredIssues]);

  const activeIssues = useMemo(() => {
    if (!useFilteredIssues) {
      return issues;
    }

    if (!visibleIssues.length) {
      return [];
    }

    const endOfToday = new Date();
    endOfToday.setUTCHours(23, 59);
    return visibleIssues.filter(({ endDate }) => {
      if (!endDate) {
        return true;
      }

      return getFirestoreTimestampDate(endDate).getTime() > endOfToday.getTime();
    });
  }, [visibleIssues, useFilteredIssues, issues]);

  if (showImprintPage) {
    return (
      <ImprintPreview
        {...config}
        {...(issues.length ? issues[0] : {})}
      />
    );
  }

  if (showPage) {
    return (
      <PagePreview
        {...(issues.length && issues[0]?.pages ? issues[0].pages[0] : {})}
      />
    );
  }

  if (activeIssues.length) {
    return (
      <IssuePreview
        {...config}
        {...activeIssues[0]}
      />
    );
  }

  if (visibleIssues.length) {
    return (
      <ArchivedPreview
        {...config}
        issues={visibleIssues}
      />
    );
  }

  return (
    <EmptyContentPreview />
  );
});


MagazinePageContentPreview.propTypes = {
  config: PropTypes.shape({}),
  issues: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ])),
  })),
  useFilteredIssues: PropTypes.bool,
  showImprintPage: PropTypes.bool,
  showPage: PropTypes.bool,
};

MagazinePageContentPreview.defaultProps = {
  config: {},
  issues: [],
  useFilteredIssues: true,
  showImprintPage: false,
  showPage: false,
};


export default MagazinePageContentPreview;
