import { createSelector } from 'reselect';


const getLocalState = (state) => state.statistics;

export const getStatisticsList = createSelector(
  getLocalState,
  ({ list }) => list,
);

export const getFetching = createSelector(
  getLocalState,
  ({ fetching }) => fetching,
);

export default {
  getFetching,
};
