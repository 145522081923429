import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { decode } from 'he';

import ContentSectionTitle from '@pro/web-common/components/content-section-title';


const SectionItemContent = React.memo(({ titleLabel, data: { title, ...rest }, onDelete, onVisibilityChange, isVisible, onChange, toggleSection, isExpanded, ContentEditor }) => {
  const [isEditorOpened, setIsEditorOpened] = useState(isExpanded);

  const toggleEditor = useCallback(
    () => {
      toggleSection();
      setIsEditorOpened(!isEditorOpened);
    },
    [isEditorOpened, toggleSection],
  );

  const decodedTitle = useMemo(() => decode(title), [title]);

  useEffect(() => {
    setIsEditorOpened(isExpanded);
  }, [isExpanded]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <ContentSectionTitle
        titleLabel={titleLabel}
        value={decodedTitle}
        onEdit={ContentEditor ? toggleEditor : null}
        onDelete={onDelete}
        onVisibilityChange={onVisibilityChange}
        isVisible={isVisible}
        onChange={(value) => onChange({ title: value })}
      />

      {ContentEditor && (
        <ContentEditor
          isOpened={isEditorOpened}
          onChange={onChange}
          {...rest}
        />
      )}
    </Box>
  );
});

SectionItemContent.propTypes = {
  titleLabel: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      filePath: PropTypes.string,
    }),
    html: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  ContentEditor: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  isVisible: PropTypes.bool,
};

SectionItemContent.defaultProps = {
  titleLabel: '',
  isExpanded: false,
  onVisibilityChange: null,
  ContentEditor: null,
  isVisible: true,
  onDelete: null,
};


export default SectionItemContent;
