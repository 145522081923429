import { pick } from 'ramda';
import memoize from 'lodash.memoize';

import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_BOTTOM_BAR_COLOR,
  DEFAULT_FONT_COLOR,
  DEFAULT_ICON_ACTIVE_COLOR,
  DEFAULT_ICON_COLOR,
  DEFAULT_LOGO_BAR_COLOR,
  DEFAULT_SECTION_HEADER_COLOR,
  DEFAULT_SECTION_HEADER_TEXT_COLOR,
  DEFAULT_SUB_SECTION_HEADER_COLOR,
  DEFAULT_SUB_SECTION_HEADER_TEXT_COLOR,
  DEFAULT_TOP_BAR_COLOR,
  typefaceValues,
} from 'constants/brand-config';
import { ignoreHasSubscriptions } from 'constants/project-config';


const BRAND_MODEL = [
  'organisationName',
  'enableSharing',
  'topBarColor',
  'logoBarColor',
  'backgroundColor',
  'bottomBarColor',
  'iconColor',
  'iconActiveColor',
  'fontColor',
  'sectionHeaderColor',
  'sectionHeaderTextColor',
  'subSectionHeaderColor',
  'subSectionHeaderTextColor',
  'typeface',
  'rtfColors',
];

export const extractDBModel = (data) => (pick(BRAND_MODEL, data));

export const normalizeBrand = (data) => {
  const {
    organisationName = '',
    logo,
    enableSharing = true,
    topBarColor,
    logoBarColor,
    backgroundColor,
    bottomBarColor,
    iconColor,
    iconActiveColor,
    fontColor,
    typeface,
    rtfColors,
    sectionHeaderColor,
    sectionHeaderTextColor,
    subSectionHeaderColor,
    subSectionHeaderTextColor,
    isConfigured,
    availableProducts,
    hasSubscriptions,
    isEnterprise,
    enterpriseHomeImage,
    enterpriseHomeText,
    isDynamic = false,
    id,
  } = data || {};

  const values = {
    organisationName,
    logo,
    newLogoFile: null,
    enableSharing,
    topBarColor: topBarColor || DEFAULT_TOP_BAR_COLOR,
    logoBarColor: logoBarColor || DEFAULT_LOGO_BAR_COLOR,
    backgroundColor: backgroundColor || DEFAULT_BACKGROUND_COLOR,
    bottomBarColor: bottomBarColor || DEFAULT_BOTTOM_BAR_COLOR,
    iconColor: iconColor || DEFAULT_ICON_COLOR,
    iconActiveColor: iconActiveColor || DEFAULT_ICON_ACTIVE_COLOR,
    fontColor: fontColor || DEFAULT_FONT_COLOR,
    typeface: typeface || typefaceValues[0].value,
    rtfColors,
    sectionHeaderColor: sectionHeaderColor || DEFAULT_SECTION_HEADER_COLOR,
    sectionHeaderTextColor: sectionHeaderTextColor || DEFAULT_SECTION_HEADER_TEXT_COLOR,
    subSectionHeaderColor: subSectionHeaderColor || DEFAULT_SUB_SECTION_HEADER_COLOR,
    subSectionHeaderTextColor: subSectionHeaderTextColor || DEFAULT_SUB_SECTION_HEADER_TEXT_COLOR,
    isConfigured,
    availableProducts,
    hasSubscriptions: ignoreHasSubscriptions ? true : hasSubscriptions,
    hasCurrencySetup: hasSubscriptions,
    isEnterprise,
    enterpriseHomeImage,
    enterpriseHomeText,
    isDynamic,
    id,
  };

  return values;
};

export const getSubscriptionPermissions = memoize((brand) => {
  const { availableProducts, hasSubscriptions } = brand || {};
  const canAddProduct = availableProducts !== 0;

  return {
    canAddProduct,
    canAddManualVersions: hasSubscriptions,
    canAddUsers: hasSubscriptions,
    canAddTags: hasSubscriptions,
  };
});
