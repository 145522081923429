import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';

import DirectoryProfileForm from '@pro/web-common/components/directory-profile-form';


const ConfigureDirectoryProfileModal = React.memo(({ isAuthorized, isCreator, isEdit, onClose, onSubmit, onResetPassword, onError, initialValues, directoryIndex }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="md"
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      {isEdit ? 'Edit' : 'Add'}
      {' '}
      Profile
    </DialogTitle>

    <DialogContent>
      <Box mb={2}>
        <DirectoryProfileForm
          isAuthorized={isAuthorized}
          isCreator={isCreator}
          isEdit={isEdit}
          withRemindPassword
          withLimitsConfig
          initialValues={initialValues}
          directoryIndex={directoryIndex}
          onSubmit={onSubmit}
          onResetPassword={onResetPassword}
          onClose={onClose}
          onError={onError}
        />
      </Box>
    </DialogContent>
  </Dialog>
));


ConfigureDirectoryProfileModal.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isCreator: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  directoryIndex: PropTypes.number,
};

ConfigureDirectoryProfileModal.defaultProps = {
  isEdit: false,
  initialValues: null,
  directoryIndex: null,
};


export default ConfigureDirectoryProfileModal;
