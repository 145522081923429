import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import meta from 'content/meta';


const GDPRPage = React.memo(() => (
  <>
    {meta.gdpr}

    <Container maxWidth="md">
      <PageTitle
        title="GDPR"
        subtitle={<>Your rights under GDPR</>}
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Typography
            variant="body2"
          >
            KAPPOW LTD collects Personal identification information solely for the purpose of providing functionality in
            our admin website - we do not collect any data from Users of the app. <br /><br />
            On our site, this will include name, email address, phone number, etc. <br /><br />
            <strong>How do we collect your data?</strong><br />
            You directly provide us with most of the data we collect. We collect data and process data when you: <br />
            Register online or place an order for any of our products or services.<br />
            Use or view our website via your browser’s cookies. <br /><br />
            <strong>How will we use your data?</strong><br />
            Process your order and manage your account.<br /><br />
            <strong>How do we store your data?</strong><br />
            We securely store your data in the cloud and will keep your data until you no longer use our services, and
            up to 6 months thereafter. Once this time period has expired. <br /><br />
            <strong>What are your data protection rights?</strong><br />
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled
            to the following: <br />
            <strong>The right to access</strong> – You have the right to request copies of your personal data. We may
            charge you a small fee for this service. <br />
            <strong>The right to rectification</strong> – You have the right to request we correct any information you
            believe is
            inaccurate. You also have the right to request we complete the information you believe is
            incomplete. <br />
            <strong>The right to erasure</strong> – You have the right to request your personal data is erased
            under certain conditions.<br />
            <strong>The right to restrict processing</strong> – You have the right to request we restrict the processing
            of your personal data, under certain conditions. <br />
            <strong>The right to object to processing</strong> – You have the right to object to our processing of your
            personal data, under certain conditions. <br />
            <strong>The right to data portability</strong> – You have the right to request we transfer the data that we
            have collected to another organization, or directly to you, under certain conditions.<br /><br />
            <strong>If you make a request, we have one month to respond to you.</strong><br />
            If you would like to exercise any of these rights, please contact us at our email: hello at
            kappowapps.com <br />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <Typography
            variant="body2"
          >
            Cookies Cookies are text files placed on your computer to collect standard Internet log information and
            visitor behavior information. When you visit our
            website, we may collect information from you automatically through cookies or similar
            technology. <br /><br />
            For further information, visit all about cookies.org. <br /><br />
            <strong>How do we use cookies?</strong><br />
            We use cookies in a range of ways to improve your experience on our website, including: <br />
            Keeping you signed in<br />
            Understanding how you use our website <br /><br />
            <strong> What types of cookies do we use?</strong> <br />
            There are a number of different types of cookies, however, our website uses: <br />
            Functionality – We use these cookies so that we recognize you on our website and remember your previously
            selected preferences. These could include what language you prefer and location you are in. A mix
            of first-party and third-party cookies are used. <br /><br />
            <strong> How to manage cookies</strong> <br />
            You can set your browser not to accept cookies, and the above website tells you how to remove cookies from
            your browser. However, in a few cases,
            some of our website features may not function as a result. <br />
            Our website contains links to other websites. Our policy applies only to our website, so if you click on a
            link to another website, you should read their policy. <br /><br />
            <strong> Changes to our policy</strong> <br />
            We keep our policy under regular review and place any updates on this web page. <br />
            This policy was last updated on 3 February 2020. <br /><br />
            <strong> How to contact us</strong><br />
            If you have any questions about this policy, the data we hold on you, or you would like to exercise one of
            your data protection rights, please do not hesitate
            to contact us by email at team at hello at kappowapps.com <br /><br />
            <strong>How to contact the appropriate authority</strong> <br />
            Should you wish to report a complaint or if you feel that we have not addressed your concern in a
            satisfactory
            manner, you may contact the Information Commissioner’s Office.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </>
));

GDPRPage.propTypes = {};

GDPRPage.defaultProps = {};


export default GDPRPage;
