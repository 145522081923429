import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  IconButton,
  TextField,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import Tooltip from '@pro/web-common/components/conditional-tooltip';

import { styles } from './styles';


const ContentSectionTitle = React.memo(({ titleLabel, value, onEdit, onDelete, onVisibilityChange, onChange, isVisible }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <TextField
        fullWidth
        value={value}
        label={titleLabel}
        InputLabelProps={{ shrink: true }}
        onChange={({ target: { value: newValue } }) => onChange(newValue)}
      />

      <Box
        display="flex"
        flexDirection="row"
      >
        {onEdit && (
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}

        {onVisibilityChange && (
          <Tooltip
            title={isVisible ? 'Hide' : 'Show'}
            isShown
          >
            <IconButton onClick={onVisibilityChange}>
              {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        )}

        {
          onDelete && (
            <IconButton onClick={onDelete}>
              <DeleteIcon color="secondary" />
            </IconButton>
          )
        }
      </Box>
    </Box>
  );
});

ContentSectionTitle.propTypes = {
  titleLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func,
  isVisible: PropTypes.bool,
};

ContentSectionTitle.defaultProps = {
  value: '',
  onEdit: null,
  onDelete: null,
  onVisibilityChange: null,
  isVisible: true,
};


export default ContentSectionTitle;
