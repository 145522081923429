import React from 'react';

import {
  Grid,
  Box,
  Link,
  Typography,
  Container,
} from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';
import { SOCIAL_LINKS } from 'constants/links';

import { FOOTER_TEXT_LINE_1, FOOTER_TEXT_LINE_2 } from 'content/texts';

import FooterLink from './footer-link';
import { styles } from './styles';


const Footer = React.memo(() => {
  const classes = styles();

  return (
    <footer className={classes.container}>
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            item
            xs={6}
            sm={3}
          >
            <FooterLink
              title="Support"
              to="/support"
            />

          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <FooterLink
              title="GDPR"
              to="/gdpr"
            />
            <FooterLink
              title="Privacy"
              to="/privacy"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            {SOCIAL_LINKS.map(({ id, href, icon }) => (
              <Link
                rel="noopener"
                target="_blank"
                key={id}
                href={href}
                className={classes.socialLink}
              >
                <img
                  src={icon}
                  alt={id}
                />
              </Link>
            ))}
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <Box textAlign="right">
              {STORE_LINKS.map(({ id, href, icon }) => (
                <Link
                  key={id}
                  href={href}
                  className={classes.storeLink}
                >
                  <img
                    src={icon}
                    alt={id}
                  />
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Box
          textAlign="center"
          mt={4}
        >
          <Typography className={classes.text}>
            {FOOTER_TEXT_LINE_1}
            <br />
            {FOOTER_TEXT_LINE_2}
          </Typography>
        </Box>
      </Container>
    </footer>
  );
});


export default Footer;
