import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import {
  Box,
  Button,
  Collapse,
} from '@material-ui/core';


import ConfigureContactModal from '@pro/web-common/components/configure-contact-modal';
import ContactsListing from '@pro/web-common/components/contacts-listing';


const DirectoryPageEditor = React.memo(({ isOpened, initialValues: { contacts }, onSubmit }) => {
  const [contactsData, setSectionsData] = useState(contacts || []);
  const [isConfigureContactModalOpen, setIsConfigureContactModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const editSection = useCallback((data) => {
    setModalData(data);
    setIsConfigureContactModalOpen(true);
  }, []);

  useEffect(() => {
    if (!isConfigureContactModalOpen) {
      setModalData(null);
    }
  }, [isConfigureContactModalOpen]);

  const handleSectionUpdate = useCallback((index, data) => {
    if (index !== undefined) {
      setSectionsData(update(contactsData, { [index]: { $set: data } }));
    } else {
      setSectionsData(update(contactsData, { $push: [data] }));
    }

    setIsConfigureContactModalOpen(false);
  }, [contactsData]);

  const handleSectionDelete = useCallback((index) => {
    setSectionsData(update(contactsData, { $splice: [[index, 1]] }));
  }, [contactsData]);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box
        mt={2}
        mb={2}
      >
        <ContactsListing
          contacts={contactsData}
          onEdit={editSection}
          onDelete={handleSectionDelete}
        />

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsConfigureContactModalOpen(true)}
          >
            Add Contact
          </Button>
        </Box>

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit({ contacts: contactsData })}
          >
            Save
          </Button>
        </Box>
      </Box>

      {isConfigureContactModalOpen && (
        <ConfigureContactModal
          isEdit={!!modalData}
          initialValues={modalData}
          onClose={() => setIsConfigureContactModalOpen(false)}
          onSubmit={handleSectionUpdate}
        />
      )}
    </Collapse>
  );
});


DirectoryPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contacts: PropTypes.arrayOf(PropTypes.shape({
      contactName: PropTypes.string.isRequired,
      email: PropTypes.string,
      phone: PropTypes.string,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

DirectoryPageEditor.defaultProps = {};


export default DirectoryPageEditor;
