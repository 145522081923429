import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Grid,
  DialogContentText,
} from '@material-ui/core';


const ExistingDirectoryProfileModal = React.memo(({ profile, onSubmit, onCancel }) => (
  <Dialog
    open
    onClose={onCancel}
    aria-labelledby="form-dialog-title"
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      Profile already exist
    </DialogTitle>

    <DialogContent>
      <DialogContentText component="div">
        Profile with email&nbsp;
        <Box
          display="inline"
          fontWeight="fontWeightBold"
          component="p"
        >
          {profile.email}
        </Box>&nbsp;
        is already exist. Use existing profile?
      </DialogContentText>
      <Grid
        item
        xs={12}
      >
        <Box
          mt={2}
          mb={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Box mr={1}>
            <Button
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Yes
          </Button>
        </Box>
      </Grid>
    </DialogContent>
  </Dialog>
));


ExistingDirectoryProfileModal.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default ExistingDirectoryProfileModal;
