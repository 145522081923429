import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import FileInput from '@pro/web-common/components/file-input';

import { styles } from './styles';


const LogoUploader = React.memo(({ formats, fileName, filePath, onFileChange, disabled, id, updateLabel, addLabel, onDelete, ...rest }) => {
  const classes = styles();

  const onLogoChange = useCallback((file) => {
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      onFileChange({ file, filePath: result });
    };

    reader.readAsDataURL(file);
  }, [onFileChange]);

  return (
    <>
      <FileInput
        id={id}
        onChange={(file) => onLogoChange(file)}
        formats={formats}
        submitLabel={fileName && fileName.length > 0 ? updateLabel : addLabel}
        disabled={disabled}
        fileName={fileName}
        onDelete={onDelete}
        {...rest}
      />

      {filePath && (
        <Box mt={1}>
          <img
            className={classes.imagePreview}
            alt=""
            src={filePath}
          />
        </Box>
      )}
    </>
  );
});

LogoUploader.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  filePath: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  updateLabel: PropTypes.string,
  addLabel: PropTypes.string,
  onDelete: PropTypes.func,
};

LogoUploader.defaultProps = {
  fileName: '',
  filePath: null,
  disabled: false,
  updateLabel: 'Update logo',
  addLabel: 'Add your logo',
  onDelete: null,
};


export default LogoUploader;
