import { PAGE_TYPE } from 'constants/product-config';


export const getMenuOptions = (productsOptions = [], selectedProductId) => {
  const productsOptionsWithMenu = productsOptions.filter(({ pages = [] }) => {
    const menuPages = pages.filter(({ pageTypeId }) => pageTypeId === (PAGE_TYPE.MENU && PAGE_TYPE.MENU.id) || pageTypeId === (PAGE_TYPE.TIMETABLE && PAGE_TYPE.TIMETABLE.id));
    return menuPages.length > 0
  });

  const productOption = productsOptionsWithMenu.find(({ value }) => value === selectedProductId) || {};
  const { pages = [] } = productOption;
  const menuPages = pages.filter(({ pageTypeId }) => pageTypeId === (PAGE_TYPE.MENU && PAGE_TYPE.MENU.id) || pageTypeId === (PAGE_TYPE.TIMETABLE && PAGE_TYPE.TIMETABLE.id));
  const options = menuPages.map(({ id, label }) => ({
    value: id,
    label,
  }));

  return options;
};

export const getPagesOptions = (productsOptions, selectedProductId) => {
  const productOption = productsOptions.find(({ value }) => value === selectedProductId) || {};
  const { pages } = productOption || [];
  const options = pages.map(({ id, label }) => ({
    id,
    label,
  }));

  return options;
};
