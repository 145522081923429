import React, { useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  SwapVert as SwapVertIcon,
} from '@material-ui/icons';

import { PAGE_TYPE } from 'constants/product-config';
import { actions as messagesActions } from '@pro/web-common/core/messages/actions';
import { getFilteredMessages } from '@pro/web-common/core/messages/utils';
import {
  getQuickMessagesList,
  getFetching,
  getCreatingState,
  getGeneratingReportState,
} from '@pro/web-common/core/messages/selectors';
import { getActiveProductsList } from '@pro/web-common/core/product/selectors';
import { getProfilesCollection, getProfilesLimitsCollectionByProductId } from '@pro/web-common/core/profile/selectors';
import { UserRoleContext } from '@pro/web-common/containers/providers';
import { getLimitsData } from '@pro/web-common/core/profile/utils';

import PageTitle from '@pro/web-common/components/page-title';
import DataWithLoader from '@pro/web-common/components/data-with-loader';
import QuickMessagesListing from '@pro/web-common/components/quick-messages-listing';
import ProductFilters from '@pro/web-common/components/product-filters';
import Checkbox from '@pro/web-common/components/checkbox';
import GenerateReport from '@pro/web-common/components/generate-report';

import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';

import {
  QUICK_MESSAGES_TITLE,
  QUICK_MESSAGES_SUBTITLE,
  QUICK_MESSAGES_NO_ITEMS_NOTE,
} from 'content/texts';

import { styles } from './styles';


const QuickMessages = React.memo(({ getMessages, quickMessages, isFetching, products, creatingState, createQuickMessage, deleteQuickMessage, deleteTrackTraceMessage, generateUsersReport, generatingReportState, profiles, profileLimits }) => {
  const classes = styles();

  const { isDemo, isProfile } = useContext(UserRoleContext);

  const [sortByDateAsc, setSortByDateAsc] = useState(true);
  const [showTrackTraceMessages, setShowTrackTraceMessages] = useState(false);
  const [filters, setFilters] = useState({
    productId: null,
    fromDate: null,
    toDate: null,
  });

  const productsOptions = useMemo(() => products.map(({ id, brandId, productName, isLimited, pages, address, pushMessagesRadius }) => ({
    value: id,
    label: productName,
    isLimited,
    pages,
    address,
    pushMessagesRadius,
    brandId,
    ...(isProfile ? getLimitsData(profileLimits[id], MESSAGE_TYPE.QUICK, quickMessages) : {}),
  })), [products, quickMessages]);

  const isTrackAndTraceMessageAvailable = useMemo(() => (!!PAGE_TYPE.MENU || !!PAGE_TYPE.TIMETABLE) && !isProfile, []);

  const hasAvailableProducts = productsOptions && productsOptions.length > 0;

  const filteredQuickMessages = useMemo(() => getFilteredMessages({
    filters: {
      ...filters,
      sortByDateAsc,
      showTrackTraceMessages,
    },
    list: quickMessages,
    startDateField: 'date',
    endDateField: 'date',
  }), [quickMessages, filters, sortByDateAsc, showTrackTraceMessages]);

  useEffect(() => {
    if (!isDemo) {
      getMessages();
    }
  }, [isDemo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title={QUICK_MESSAGES_TITLE}
        subtitle={QUICK_MESSAGES_SUBTITLE}
      >
        {!hasAvailableProducts && (
          <Typography color="error">
            {QUICK_MESSAGES_NO_ITEMS_NOTE}
          </Typography>
        )}
      </PageTitle>

      <DataWithLoader isLoaded={!isFetching || !(quickMessages.length === 0)}>
        <ProductFilters
          onFiltersChange={setFilters}
        />

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              mt={2}
              mb={[4, 0]}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                {isTrackAndTraceMessageAvailable && (
                  <Box mr={1}>
                    <Checkbox
                      id="showTrackTrace"
                      name="showTrackTrace"
                      label="Show Contact Trace"
                      value={showTrackTraceMessages}
                      onChange={({ target: { checked } }) => setShowTrackTraceMessages(checked)}
                    />
                  </Box>
                )}

                <IconButton
                  onClick={() => setSortByDateAsc(!sortByDateAsc)}
                >
                  <SwapVertIcon color="primary" />
                </IconButton>
              </Box>

              <Card className={classes.cardContainer}>
                <CardContent>
                  <QuickMessagesListing
                    messages={filteredQuickMessages}
                    onQuickMessageDelete={deleteQuickMessage}
                    onTrackTraceMessageDelete={deleteTrackTraceMessage}
                    onMessageAdd={createQuickMessage}
                    productsOptions={productsOptions}
                    creatingState={creatingState}
                    disabled={!hasAvailableProducts}
                    selectedProductId={filters.productId}
                    profiles={profiles}
                  />
                </CardContent>
              </Card>

              {isTrackAndTraceMessageAvailable && !isDemo && (
                <Card className={classes.cardContainer}>
                  <CardContent>
                    <GenerateReport
                      generatingReportState={generatingReportState}
                      onGenerateReport={generateUsersReport}
                    />
                  </CardContent>
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>
      </DataWithLoader>
    </Container>
  );
});

QuickMessages.propTypes = {
  getMessages: PropTypes.func.isRequired,
  quickMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  createQuickMessage: PropTypes.func.isRequired,
  creatingState: PropTypes.shape({}).isRequired,
  deleteQuickMessage: PropTypes.func.isRequired,
  deleteTrackTraceMessage: PropTypes.func.isRequired,
  generateUsersReport: PropTypes.func.isRequired,
  generatingReportState: PropTypes.shape({
    fetching: PropTypes.bool,
  }).isRequired,
  profiles: PropTypes.shape({}),
  profileLimits: PropTypes.shape({}),
};

QuickMessages.defaultProps = {
  profiles: {},
  profileLimits: {},
};


const mapStateToProps = (state) => ({
  quickMessages: getQuickMessagesList(state),
  isFetching: getFetching(state),
  products: getActiveProductsList(state),
  creatingState: getCreatingState(state),
  generatingReportState: getGeneratingReportState(state),
  profiles: getProfilesCollection(state),
  profileLimits: getProfilesLimitsCollectionByProductId(state),
});

const mapDispatchToProps = {
  getMessages: messagesActions.getMessages,
  createQuickMessage: messagesActions.createQuickMessage,
  deleteQuickMessage: messagesActions.deleteQuickMessage,
  deleteTrackTraceMessage: messagesActions.deleteTrackTraceMessage,
  generateUsersReport: messagesActions.generateUsersReport,
};


export default connect(mapStateToProps, mapDispatchToProps)(QuickMessages);
