import React from 'react';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import SectionImage from 'assets/images/home/Manual_Builder_Product_Angle.png';

import { styles } from './styles';


const BuildManualSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      pt={6}
      pb={4}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              Talk direct to YOUR customers<br />
            </Typography>
            <Typography>
              <br />
              Selling via third party websites like Amazon or bricks and mortar retailers means you dont have direct access to your customers or product users.<br />
              Including a QR code IFU in your packaging creates a brand new direct to consumer channel - so you do.<br />
              Provide added value content, instructions for use, product updates, messaging and much more - talk direct to your customers.<br />
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                A completely new &amp; uncluttered D2C channel
              </Box>
            </Typography>

          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              Increase sustainability &amp; profitability
            </Typography>

            <Typography>
              <br />
              Adding your Product Manuals to your customers mobile devices reduces your Green House Gas (GHG) emissions by negating the need for print collateral AND cuts your packaging costs over time.<br />
              ManualPro provide the QR codes and short links you can include in all your print and digital media, upping your sustainability and increasing your margins whilst providing an uncluttered D2C channel.
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                Reduce costs &amp; environmental impact
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Box
              mt={4}
              textAlign="left"
            >
              <Typography
                gutterBottom
                variant="h4"
              >
                Why ManualPro.co?
              </Typography>

            </Box>
            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>Simple to use &apos;no code&apos; admin</li>
                <li>Style with your logo, images, colors &amp; fonts</li>
                <li>Add as many products as you wish</li>
                <li>Include IFU text, images &amp; video</li>
                <li>Add guarantees &amp; warranties</li>
                <li>Translate into any language automatically</li>
                <li>Announce product launches, updates &amp; news</li>
                <li>Use powerful push messaging</li>
                <li>Share product videos &amp; how to&apos;s</li>
                <li>No GDPR impact, data is not collected</li>
                <li>Update 24/7, 365 days a year</li>
              </ul>
            </Box>
            <Box
              mt={1}
              display="block"
              component="span"
              fontWeight="fontWeightBold"
            >
                ManualPro changes into your own fully functional product app
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="Mobile Phone App Instructions For Use IFU"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});


BuildManualSection.propTypes = {};

BuildManualSection.defaultProps = {};


export default BuildManualSection;
