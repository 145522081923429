import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import ContactDetails from './contact-details';

// import { styles } from './styles';


const ContactItem = React.memo(({ contactName, email, phone, onEdit, onDelete }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    mt={2}
    mb={2}
  >
    <Box>
      <Typography>
        {contactName}
      </Typography>

      {email && (
        <ContactDetails
          iconId="Email"
          value={email}
        />
      )}

      {phone && (
        <ContactDetails
          iconId="Call"
          value={phone}
        />
      )}
    </Box>

    <Box>
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>

      <IconButton onClick={onDelete}>
        <DeleteIcon color="secondary" />
      </IconButton>
    </Box>
  </Box>
));

ContactItem.propTypes = {
  contactName: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ContactItem.defaultProps = {
  email: '',
  phone: '',
};


export default ContactItem;
