import update from 'immutability-helper';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { move } from 'ramda';

import {
  Box,
  Typography,
} from '@material-ui/core';

import DirectoryItem from '@pro/web-common/components/directory-item';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';

import { styles } from './styles';


const DirectoriesListing = React.memo(({ directories, profilesList, onEdit, onDelete, onUpdate, onAddProfile, onEditProfile }) => {
  const classes = styles();

  const onDirectoriesSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const newDirectories = move(oldIndex, newIndex, directories);

    onUpdate(newDirectories);
  }, [directories, onUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onProfilesUpdate = useCallback((nextProfiles, index) => {
    const newDirectories = update(directories, { [index]: { $merge: { profiles: nextProfiles } } });

    onUpdate(newDirectories);
  }, [directories, onUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {directories.length === 0 ? (
        <Box
          textAlign="center"
          p={2}
        >
          <Typography variant="body2">You have not added directories yet</Typography>
        </Box>
      ) : (
        <Box ml={2}>
          <SortableList
            onSortEnd={onDirectoriesSortEnd}
            useDragHandle
            lockAxis="y"
          >
            {directories.map(({ directoryName, profiles }, index) => (
              <SortableListItem
                key={index} // eslint-disable-line react/no-array-index-key
                index={index}
              >
                <Box className={classes.itemContainer}>
                  <DirectoryItem
                    onEdit={() => onEdit({ index, directoryName, profiles })}
                    onDelete={() => onDelete(index)}
                    onProfilesUpdate={(nextProfiles) => onProfilesUpdate(nextProfiles, index)}
                    onEditProfile={(profile) => onEditProfile(profile, index)}
                    onAddProfile={() => onAddProfile(index)}
                    profilesList={profilesList}
                    directoryName={directoryName}
                    profiles={profiles}
                  />
                </Box>
              </SortableListItem>
            ))}
          </SortableList>
        </Box>
      )}
    </>
  );
});

DirectoriesListing.propTypes = {
  directories: PropTypes.arrayOf(PropTypes.shape({
    directoryName: PropTypes.string.isRequired,
  })),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAddProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  profilesList: PropTypes.shape({}),
};

DirectoriesListing.defaultProps = {
  directories: [],
  profilesList: {},
};


export default DirectoriesListing;
