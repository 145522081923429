import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Switch,
} from '@material-ui/core';

import { getStatisticsList } from '@pro/web-common/core/statistics/selectors';
import { actions as statisticsActions } from '@pro/web-common/core/statistics/actions';
import { UserRoleContext } from '@pro/web-common/containers/providers';

import DataWithLoader from '@pro/web-common/components/data-with-loader';
import ProductFilters from '@pro/web-common/components/product-filters';
import PageTitle from '@pro/web-common/components/page-title';
import PieChart from '@pro/web-common/components/charts/pie';
import LineChart from '@pro/web-common/components/charts/lines';

import { generateLineChartProps, generatePieChartProps, getTotalNumberOfUsers, getPeriodData } from './utils';


const prevMonth = new Date().setMonth(new Date().getMonth() - 1);
const Statistics = React.memo(({ isFetching, statistics, getStatistics }) => {
  const { isDemo } = useContext(UserRoleContext);

  const [filters, setFilters] = useState({
    productId: null,
    fromDate: prevMonth,
    toDate: new Date(),
  });
  const [isTotalVisitsChecked, setIsTotalVisitsChecked] = useState(true);
  const productStatistics = statistics[filters.productId];

  const periodData = useMemo(() => getPeriodData(productStatistics, filters.fromDate, filters.toDate), [filters.fromDate, filters.toDate, productStatistics]);
  const lineChartData = useMemo(() => generateLineChartProps(periodData, isTotalVisitsChecked), [periodData, isTotalVisitsChecked]);
  const pieChartData = useMemo(() => generatePieChartProps(periodData, isTotalVisitsChecked), [periodData, isTotalVisitsChecked]);
  const totalNumberOfUsers = useMemo(() => getTotalNumberOfUsers(periodData, isTotalVisitsChecked), [periodData, isTotalVisitsChecked]);

  const onNumVisitsToggle = useCallback(() => {
    setIsTotalVisitsChecked(!isTotalVisitsChecked);
  }, [isTotalVisitsChecked]);

  useEffect(() => {
    const { productId } = filters;

    if (productId && !statistics[productId]) {
      getStatistics({ productId });
    }
  }, [statistics, filters.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Statistics"
        subtitle="Track activity of your mobile app users"
      />

      {isDemo ? (
        <Typography color="error">
          Sorry, Demo users cannot see statistics
        </Typography>
      ) : (
        <>
          <Box mb={2}>
            <ProductFilters
              initialValues={filters}
              onFiltersChange={setFilters}
            />
          </Box>

          <Box mb={2}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>Unique visits</Grid>

              <Grid item>
                <Switch
                  checked={isTotalVisitsChecked}
                  onChange={onNumVisitsToggle}
                  name="isTotalVisitsChecked"
                  color="primary"
                />
              </Grid>

              <Grid item>Total visits</Grid>
            </Grid>
          </Box>

          <DataWithLoader isLoaded={!isFetching && !!productStatistics}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Card>
                  <CardContent>
                    <Box mb={2}>
                      <Typography
                        variant="body2"
                      >
                        Number of {isTotalVisitsChecked ? 'total' : 'unique'}
                        {' '}
                        visits: {totalNumberOfUsers}
                      </Typography>
                    </Box>

                    <Divider />

                    <Box mt={2}>
                      <PieChart
                        label="IOS/Android visitors"
                        data={pieChartData}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Card>
                  <CardContent>
                    <LineChart
                      label="Monthly usage"
                      data={lineChartData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DataWithLoader>
        </>
      )}
    </Container>
  );
});

Statistics.propTypes = {
  isFetching: PropTypes.bool,
  statistics: PropTypes.shape({}),
  getStatistics: PropTypes.func.isRequired,
};

Statistics.defaultProps = {
  isFetching: false,
  statistics: {},
};

const mapStateToProps = (state) => ({
  statistics: getStatisticsList(state),
});

const mapDispatchToProps = {
  getStatistics: statisticsActions.getStatistics,
};


export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
