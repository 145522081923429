import AppStoreIcon from 'assets/images/store-icons/appStore.png';
import PlayStoreIcon from 'assets/images/store-icons/playStore.png';


export const STORE_LINKS = [
  {
    id: 'appStore',
    href: 'https://apps.apple.com/us/app/manualpro/id1498623152',
    icon: AppStoreIcon,
  },
  {
    id: 'playStore',
    href: 'https://play.google.com/store/apps/details?id=com.kappow.manualpro',
    icon: PlayStoreIcon,
  },
];

export const dynamicLink = 'https://manualproco.page.link';
export const websiteDomain = 'https://manualpro.co';
export const appStoreId = '1498623152';
export const iosBundleId = 'com.appsprout.manualpro';
export const apn = 'com.kappow.manualpro';

