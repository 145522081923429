import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

import { Pie } from 'react-chartjs-2';


const legendOpts = {
  position: 'bottom',
};

const PieChart = React.memo(({ data, label }) => {
  const hasValues = useMemo(() => data.datasets[0].data.filter((value) => value !== 0).length > 0, [data]);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="body2">{label}</Typography>
      </Box>

      {hasValues ? (
        <Pie
          data={data}
          legend={legendOpts}
        />
      ) : (
        <Typography variant="caption">No visitors for the selected period</Typography>
      )}
    </Box>
  );
});

PieChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  label: PropTypes.string.isRequired,
};


export default PieChart;
