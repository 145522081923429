import React from 'react';

import {
  Container,
  Grid,
  Box,
} from '@material-ui/core';

import Friends from 'assets/images/home/friendsoftheearth.png';
import Green from 'assets/images/home/greenpeace.png';
import Compare from 'assets/images/home/compare-your-footprint.png';
import Blank from 'assets/images/home/blank.png';


const LogosSection = React.memo(() => (
  <Box
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Friends}
            alt="Friend Of The Earth like ManualPro"
          />
          <p>&apos;Fantastic idea ...&apos;</p>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Green}
            alt="Greenpeace loves Manual Pro"
          />
          <p>&apos;What a great idea!&apos;</p>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Compare}
            alt="Compare Your Footprint likes Manual Pro"
          />
          <p>&apos;Incalculable savings could be made globally.&apos;</p>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
        >
          <img
            src={Blank}
            alt="not here yet"
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


LogosSection.propTypes = {};

LogosSection.defaultProps = {};


export default LogosSection;
