import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { getFirestoreTimestampDate, formatDate, formatTime } from '@pro/web-common/utils/date';


import { styles } from './styles';


const TrackTraceMessageListingItem = React.memo(({ link, checkInDateTime, date, onDelete, isDeleting }) => {
  const classes = styles();

  const dateString = useMemo(() => {
    let formattedDate;
    if (checkInDateTime instanceof Date) {
      formattedDate = checkInDateTime;
    } else if (typeof checkInDateTime === 'string') {
      formattedDate = new Date(checkInDateTime);
    } else {
      formattedDate = getFirestoreTimestampDate(checkInDateTime);
    }

    return `${formatDate(formattedDate)} ${formatTime(formattedDate)}`;
  }, [checkInDateTime]);

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Typography
          variant="body2"
          gutterBottom
        >
          Contact Trace: {link}
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
        >
          For the users checked in at {dateString}
        </Typography>

        <Typography variant="caption">
          Sent {date}
        </Typography>
      </Box>

      <IconButton
        onClick={onDelete}
        disabled={isDeleting}
      >
        <DeleteIcon color="secondary" />
      </IconButton>
    </Box>
  );
});

TrackTraceMessageListingItem.propTypes = {
  link: PropTypes.string.isRequired,
  checkInDateTime: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  date: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
};

TrackTraceMessageListingItem.defaultProps = {
  isDeleting: false,
};


export default TrackTraceMessageListingItem;
