import { find, propEq, findIndex } from 'ramda';
import { v4 } from 'uuid';
export var createConstants = function createConstants(constants, constantType) {
  return constants.reduce(function (acc, constant) {
    var prefix = constantType ? "".concat(constantType, "/") : '';
    acc[constant] = "".concat(prefix).concat(constant);
    return acc;
  }, {});
};
export var findObjectByProp = function findObjectByProp(array, key, value) {
  return find(propEq(key, value))(array);
};
export var findIndexByProp = function findIndexByProp(array, key, value) {
  return findIndex(propEq(key, value))(array);
};
export var generateId = function generateId() {
  return v4();
};
export var waitFor = function waitFor(ms) {
  return new Promise(function (resolve) {
    return setTimeout(function () {
      return resolve();
    }, ms);
  });
};
export var getPlainTextFromHtml = function getPlainTextFromHtml(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, '');
};