import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { colorPropsSchema } from '@pro/web-common/utils/style';

import { styles } from './styles';


const ImprintPreview = React.memo(({
  imprintHtml,
  logo,
  imprintLogo,
  name,
  showIssueName,
  typeface,
  pages,
  imprintBackgroundColor,
  imprintFontColor,
  sectionBackgroundColor,
  sectionTextColor,
}) => {
  const classes = styles({ typeface, sectionBackgroundColor, sectionTextColor, imprintBackgroundColor, imprintFontColor });

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box>
          {
            (imprintLogo || logo) && (
              <Box className={classes.logoContainer}>
                <img
                  src={imprintLogo?.url || logo?.url}
                  alt=""
                  className={classes.logo}
                />
              </Box>
            )
          }

          {
            showIssueName && (
              <Typography className={classes.issueName}>{name}</Typography>
            )
          }

          <Box className={classes.pageNavigationContainer}>
            <Typography className={classes.pageNavigationTitle}>Contents</Typography>

            {
              pages.map((pageName, index) => (
                <Box
                  key={index} // eslint-disable-line react/no-array-index-key
                  className={classes.pageNameContainer}
                >
                  <Typography
                    className={classes.pageName}
                  >
                    {pageName}
                  </Typography>

                  <ArrowForwardIos className={classes.forwardIcon} />
                </Box>
              ))
            }
          </Box>
        </Box>

        {
          imprintHtml && (
            <Box className={classes.htmlContainer}>
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: imprintHtml }} // eslint-disable-line react/no-danger
              />
            </Box>
          )
        }
      </Box>
    </Box>
  );
});


ImprintPreview.propTypes = {
  imprintHtml: PropTypes.string,
  logo: PropTypes.shape({
    ref: PropTypes.string,
    url: PropTypes.string,
  }),
  imprintLogo: PropTypes.shape({
    ref: PropTypes.string,
    url: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  showIssueName: PropTypes.bool.isRequired,
  typeface: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.string),
  imprintBackgroundColor: colorPropsSchema.isRequired,
  imprintFontColor: colorPropsSchema.isRequired,
  sectionBackgroundColor: colorPropsSchema.isRequired,
  sectionTextColor: colorPropsSchema.isRequired,
};

ImprintPreview.defaultProps = {
  imprintHtml: null,
  logo: null,
  imprintLogo: null,
  pages: [],
};


export default ImprintPreview;

