import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const formValidationSchema = () => Yup.object().shape({
  contactName: Yup.string().required(),
  email: Yup.string().email().nullable(),
  phone: Yup.string()
    .matches(REGEXP.PHONE_NUMBER, { excludeEmptyString: true }).nullable(),
  emailOrPhone: Yup.bool().when(['email', 'phone'], {
    is: (email, phone) => (!email && !phone),
    then: Yup.bool().required('Please add either email or phone.'),
    otherwise: Yup.bool(),
  }),
});
