import React from 'react';
import PropTypes from 'prop-types';

import Section from './section';


const MagazinePageContentPreview = React.memo(({ title, showTitle, titleBackgroundColor, html, image, subsections, typeface }) => (
  <>
    <Section
      title={title}
      showTitle={showTitle}
      backgroundColor={titleBackgroundColor}
      html={html}
      image={image}
      typeface={typeface}
    />

    {
      subsections.map((subsection) => (
        <Section
          key={subsection.id}
          title={subsection.title}
          showTitle={subsection.showTitle}
          backgroundColor={subsection.titleBackgroundColor}
          html={subsection.html}
          image={subsection.image}
          typeface={subsection.typeface}
        />
      ))
    }
  </>
));


MagazinePageContentPreview.propTypes = {
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  titleBackgroundColor: PropTypes.shape({}),
  html: PropTypes.string,
  image: PropTypes.shape({}),
  subsections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  typeface: PropTypes.string,
};

MagazinePageContentPreview.defaultProps = {
  title: null,
  showTitle: true,
  titleBackgroundColor: null,
  html: null,
  image: null,
  typeface: null,
};


export default MagazinePageContentPreview;
