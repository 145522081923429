import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { withFormik } from 'formik';
import update from 'immutability-helper';

import { generateId } from '@pro/web-common/utils';
import { colorPropsSchema } from '@pro/web-common/utils/style';

import AppPreview from '@pro/web-common/components/app-preview';
import ColorPicker from '@pro/web-common/components/color-picker';
import Select from '@pro/web-common/components/select';
import LogoUploader from '@pro/web-common/components/logo-uploader';
import InfoSection from '@pro/web-common/components/info-section';
import SectionTitle from '@pro/web-common/components/section-title';
import Checkbox from '@pro/web-common/components/checkbox';

import infoJson from 'constants/info.json';
import { typefaceValues } from 'constants/brand-config';

import {
  logoFormats,
  formValidationSchema,
  DEFAULT_RTF_COLOR,
} from './config';

import { styles } from './styles';


const BrandConfigurator = React.memo(({ fetching, isError, onSubmit, submitLabel, withLogoEnabled, userEmail, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formikProps;

  const classes = styles({
    typeface: values.typeface,
    fontColor: values.fontColor,
  });

  const handleLogoFileChange = useCallback(({ file, filePath }) => {
    setFieldValue('newLogoFile', file);
    setFieldValue('logoFileName', file.name);
    setFieldValue('logoImage', filePath);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRtfColorAdd = useCallback(() => {
    setFieldValue('rtfColors', [...values.rtfColors, {
      id: generateId(),
      value: DEFAULT_RTF_COLOR,
    }]);
  }, [values.rtfColors]);

  const onRtfColorEdit = useCallback((index, value) => {
    const newColors = update(values.rtfColors, {
      [index]: {
        $merge: {
          value,
        },
      },
    });
    setFieldValue('rtfColors', newColors);
  }, [values.rtfColors]);

  const onRtfColorDelete = useCallback((index) => {
    const newColors = update(values.rtfColors, { $splice: [[index, 1]] });
    setFieldValue('rtfColors', newColors);
  }, [values.rtfColors]);

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                variant="outlined"
                fullWidth
                id="organisationName"
                label="Organisation Name"
                placeholder="Organisation Name (how it should appear)"
                name="organisationName"
                value={values.organisationName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.organisationName && touched.organisationName}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <InfoSection
                infoMessage={infoJson.brand.logo}
              >
                <LogoUploader
                  id="brand-logo-uploader"
                  disabled={!withLogoEnabled}
                  formats={logoFormats}
                  fileName={values.logoFileName}
                  filePath={values.logoImage}
                  onFileChange={({ file, filePath }) => handleLogoFileChange({
                    file,
                    filePath,
                  })}
                  error={(errors.logoImage || errors.newLogoFile) && touched.logoImage}
                />
              </InfoSection>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Checkbox
                id="enableSharing"
                name="enableSharing"
                value={values.enableSharing}
                label="Enable sharing"
                onChange={({ target: { checked } }) => setFieldValue('enableSharing', checked)}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <SectionTitle title="Colours" />

              <InfoSection infoMessage={infoJson.brand.topBarColour}>
                <ColorPicker
                  label="Top Bar"
                  color={values.topBarColor}
                  onColorChange={(value) => setFieldValue('topBarColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.topBarColour}>
                <ColorPicker
                  label="Logo Bar"
                  color={values.logoBarColor}
                  onColorChange={(value) => setFieldValue('logoBarColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.backgroundColour}>
                <ColorPicker
                  label="Background"
                  color={values.backgroundColor}
                  onColorChange={(value) => setFieldValue('backgroundColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.bottomBarColour}>
                <ColorPicker
                  label="Bottom Bar"
                  color={values.bottomBarColor}
                  onColorChange={(value) => setFieldValue('bottomBarColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.iconColour}>
                <ColorPicker
                  label="Icon Colour"
                  color={values.iconColor}
                  onColorChange={(value) => setFieldValue('iconColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.iconActiveColour}>
                <ColorPicker
                  label="Icon Active Colour"
                  color={values.iconActiveColor}
                  onColorChange={(value) => setFieldValue('iconActiveColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.fontColour}>
                <ColorPicker
                  label="Font Colour"
                  color={values.fontColor}
                  onColorChange={(value) => setFieldValue('fontColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.sectionHeaderColor}>
                <ColorPicker
                  label="Section Header Colour"
                  color={values.sectionHeaderColor}
                  onColorChange={(value) => setFieldValue('sectionHeaderColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.sectionHeaderTextColor}>
                <ColorPicker
                  label="Section Header Text Colour"
                  color={values.sectionHeaderTextColor}
                  onColorChange={(value) => setFieldValue('sectionHeaderTextColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.subSectionHeaderColor}>
                <ColorPicker
                  label="Subsection Header Colour"
                  color={values.subSectionHeaderColor}
                  onColorChange={(value) => setFieldValue('subSectionHeaderColor', value)}
                />
              </InfoSection>

              <InfoSection infoMessage={infoJson.brand.subSectionHeaderTextColor}>
                <ColorPicker
                  label="Subsection Header Text Colour"
                  color={values.subSectionHeaderTextColor}
                  onColorChange={(value) => setFieldValue('subSectionHeaderTextColor', value)}
                />
              </InfoSection>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <SectionTitle title="Font / Typeface" />

              <InfoSection infoMessage={infoJson.brand.font}>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    id="typeface"
                    name="typeface"
                    options={typefaceValues}
                    value={values.typeface}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    withFontFamily
                  />
                </FormControl>
              </InfoSection>

              <Box mt={1}>
                <Typography className={classes.fontPreview}>
                  The Quick Brown Fox Jumped Over The Lazy Moon
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <InfoSection infoMessage={infoJson.brand.rtfEditorColors}>
                <SectionTitle title="RTF Editor Text Colours" />
              </InfoSection>

              {values.rtfColors.map(({ id, value: color }, index) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  key={id}
                >
                  <ColorPicker
                    color={color}
                    onColorChange={(value) => onRtfColorEdit(index, value)}
                    withTransparencyPicker={false}
                    withMarginBottom={false}
                  />

                  <Box ml={2}>
                    <IconButton
                      onClick={() => onRtfColorDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}

              <Box
                textAlign="right"
                mt={4}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onRtfColorAdd}
                >
                  Add color
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
        >
          <AppPreview
            brand={values}
            withLogoPreview
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Box
            textAlign="right"
            mt={4}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {submitLabel}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
});

BrandConfigurator.propTypes = {
  initialValues: PropTypes.shape({
    organisationName: PropTypes.string,
    logoFile: PropTypes.shape({}),
    topBarColor: colorPropsSchema,
    logoBarColor: colorPropsSchema,
    backgroundColor: colorPropsSchema,
    bottomBarColor: colorPropsSchema,
    iconColor: colorPropsSchema,
    iconActiveColor: colorPropsSchema,
    typeface: PropTypes.string,
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  withLogoEnabled: PropTypes.bool,
  userEmail: PropTypes.string,
};

BrandConfigurator.defaultProps = {
  isError: false,
  withLogoEnabled: true,
  userEmail: '',
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      organisationName = '',
      logo = null,
      enableSharing = true,
      topBarColor,
      logoBarColor,
      backgroundColor,
      bottomBarColor,
      iconColor,
      iconActiveColor,
      fontColor,
      sectionHeaderColor,
      sectionHeaderTextColor,
      subSectionHeaderColor,
      subSectionHeaderTextColor,
      typeface,
      rtfColors = [],
    } = initialValues || {};

    const {
      ref: defaultLogoFileName,
      url: defaultLogoImage = '',
    } = logo || {};

    const values = {
      organisationName,
      logoFileName: defaultLogoFileName,
      newLogoFile: null,
      logoImage: defaultLogoImage,
      enableSharing,
      topBarColor,
      logoBarColor,
      backgroundColor,
      bottomBarColor,
      iconColor,
      iconActiveColor,
      fontColor,
      sectionHeaderColor,
      sectionHeaderTextColor,
      subSectionHeaderColor,
      subSectionHeaderTextColor,
      typeface,
      rtfColors,
    };

    return values;
  },
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit({ data }),
  validationSchema: ({ withLogoEnabled }) => formValidationSchema(withLogoEnabled),
})(BrandConfigurator);
