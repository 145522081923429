import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  itemContainer: {
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    paddingBottom: theme.spacing(1),
  },
}));
