import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

import IconRenderer from '@pro/web-common/components/icon-renderer';


const ContactDetails = React.memo(({ iconId, value }) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
  >
    <IconRenderer
      fontSize={14}
      mr={1}
      iconId={iconId}
    />

    <Typography variant="body2">
      {value}
    </Typography>
  </Box>
));

ContactDetails.propTypes = {
  iconId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ContactDetails.defaultProps = {};


export default ContactDetails;
