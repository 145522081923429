import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { formValidationSchema } from './config';


const DirectoryForm = React.memo(({ onSubmit, onClose, isEdit, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formikProps;
  // TODO: show form errors
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              id="directoryName"
              label="Directory Name"
              placeholder="Directory Name"
              name="directoryName"
              InputLabelProps={{ shrink: true }}
              value={values.directoryName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.directoryName && touched.directoryName}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Box mr={1}>
                <Button
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});


DirectoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

DirectoryForm.defaultProps = {
  isEdit: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      directoryName = 'Directory',
    } = initialValues || {};

    const values = {
      directoryName,
    };

    return values;
  },
  handleSubmit: (data, { props: { initialValues, onSubmit } }) => {
    const { index } = initialValues || {};

    onSubmit(index, { ...data });
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(DirectoryForm);
