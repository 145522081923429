import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Admin1 from 'assets/images/home/Manual_Brand_Set_Up.png';
import Admin2 from 'assets/images/home/Manual_Product_Set_Up.png';
import Admin3 from 'assets/images/home/Manual_Product_Scan_QR.png';
import Admin4 from 'assets/images/home/Manual_Different_Fish.png';

import CaseItem from './case-item';


const CasesSection = React.memo(() => (
  <Box
    bgcolor="primary.additional"
    color="white"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Easy as 1, 2, 3
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
               Publish your IFUs / product manuals in ManualPro. Talk to YOUR customers and reduce your environmental impact at the same time.<br />
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Admin1}
            title="1 Set up your brand"
            subtitle="You will only need to do this once"
            description={(
              <>
                Wrap your product manuals with your brand logo, colors &amp; typeface using our simple &apos;no code&apos; admin.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Admin2}
            title="2 Add your content"
            subtitle="1st one is FREE, subscribe for more"
            description={(
              <>
                Add product manual text &amp; images, warranties, video how to&apos;s, direct purchase links, so much more. Translate into languages automatically.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Admin3}
            title="3 Tell your customers"
            subtitle="They just scan the QR code or search your product"
            description={(
              <>
                Include the QR code &amp; short link on your packaging. Replace your online PDFs with a link. Consumers just point their mobile camera at the QR code or tap the link to add your product manual app to their phones.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Admin4}
            title="A different kettle of fish"
            subtitle="We love fish, but this is different!"
            description={(
              <>
               Unrivalled support, simple pricing, environmentally friendly and financially beneficial.<br /><br />
               All in one kettle!
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


CasesSection.propTypes = {};

CasesSection.defaultProps = {};


export default CasesSection;
