import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  Box,
} from '@material-ui/core';

import { UserRoleContext } from '@pro/web-common/containers/providers';
import QuickMessageForm from '@pro/web-common/components/quick-message-form';


const ConfigureQuickMessageModal = ({ onClose, onSubmit, isSubmitting, isError, productsOptions, ...rest }) => {
  const { isOwner } = useContext(UserRoleContext);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
    >
      <Box
        pr={3}
      >
        <DialogTitle id="form-dialog-title">
          New Quick Message
        </DialogTitle>

        <Box
          mb={2}
          pt={1}
          pb={1}
          pl={3}
          pr={3}
        >
          <QuickMessageForm
            fetching={isSubmitting}
            isError={isError}
            onSubmit={onSubmit}
            onClose={onClose}
            productsOptions={productsOptions}
            withAllOption={isOwner}
            {...rest}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

ConfigureQuickMessageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
};

ConfigureQuickMessageModal.defaultProps = {
  isSubmitting: false,
  isError: false,
};


export default ConfigureQuickMessageModal;
